import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";

/**
 * @description service to call HTTP request via Axios
 */
class ReportService {

  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ReportService.vueInstance = app;
  }

  /**
   * Fetching Reports - Dispatching Action
   * For API - skye/reports/policies-issued-vs-completed 
   * @param payload 
   * @returns 
   */
  public static callingPolicyReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_POLICY_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static exportingPolicyReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_POLICY_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static callingAgentSalesReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_AGENT_SALES_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static exportingAgentSalesReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_AGENT_SALES_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static callingSkyeLoginInfoReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_SKYE_LOGIN_INFO_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static exportingSkyeLoginInfoReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_SKYE_LOGIN_INFO_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static callingAccountMasterReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_ACCOUNT_MASTER_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static exportingAccountMasterReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_ACCOUNT_MASTER_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getInvoiceList(payload, index = 1) {
    payload.page = index;
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_APPROVED_INVOICE_REPORT, payload)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          reject(error);
        })
    });

  }
  public static exportingApprovedInvoiceReport(payload) {
    payload.page = 1;
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_APPROVED_INVOICE_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static fetchingQuoteAutoSuggest(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_QUOTE_AUTO_SUGGEST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static callingLeadBySourceReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_LEAD_BY_SOURCE_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
  
  public static exportingLeadBySourceReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_LEAD_BY_SOURCE_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static fetchingLeadBySourceFilters() {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_LEAD_BY_SOURCE_FILTERS_LIST)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static generateAlmanarahReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GENERATE_AL_MANARAH_REPORT, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  
  public static getAlmanarahReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_AL_MANARAH_REPORT, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getClosedLeadReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_CLOSED_LEAD_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static exportClosedLeadReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_CLOSED_LEADS_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getDeletedLeadReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_DELETED_LEAD_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static exportDeletedLeadReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_DELETED_LEADS_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getDiscrepancyReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_DISCREPANCY_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static exportDiscrepancyReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_DISCREPANCY_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
  public static getAdminReport(payload){
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_ADMIN_DASHBOARD_REPORT, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
  public static exportAdminReport(payload){
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_ADMIN_DASHBOARD_REPORT, payload)
        .then((data:any) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getDashboardDetailsReport(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_DASHBOARD_DETAILS_REPORT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static exportDashboardDetailsReport(payload){
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_DASHBOARD_DETAILS_REPORT_LIST, payload)
        .then((data:any) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static fetchingLeadSourceFilter() {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_LEAD_SOURCE_FILTER_LIST)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

}

export default ReportService;