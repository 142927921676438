<template>
    <!-- <el-dialog
        v-if="isOpen"
        v-model="isOpen"
        title="Extention Upload Documents"
        width="80%"
        @close="close"
    > -->
        <!-- <el-card class="box-card"> -->
            <table class="table table-hover table-bai-striped table-row-bordered border gy-7 gs-7">
                <thead>
                    <tr class="bg-bai-table-header text-gray-700 text-start fw-bolder fs-6 gs-0 cursor-pointer">
                        <th class="min-w-80px">
                            Sr #.
                        </th>
                        <th class="min-w-80px"></th>
                        <th class="min-w-272px">
                            Document Type
                        </th>
                        <th class="min-w-150px">
                            File Name
                        </th>
                        <th class="min-w-150px">
                            Amended Quote Ref No
                        </th>
                        <th class="min-w-150px">
                            Uploaded On <br>
                            Uploaded By
                        </th>
                        <th class="min-w-150px">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody class="fw-bold text-gray-600">
                    <vc-extension-item
                        v-for="(ext, index) in extensions"
                        :key="index"
                        :data-option="ext"
                        :counting="index + 1"
                        :disable-upload="isDisabled"
                    />
                </tbody>
            </table>
        <!-- </el-card> -->
        <!-- <template #footer>
            <span class="dialog-footer">
                <el-button 
                    type="default" 
                    @click="close"
                >Close</el-button>
            </span>
        </template> -->

    <!-- </el-dialog> -->
</template>

<script>
import { useStore } from "vuex";
import { computed, defineComponent } from 'vue'
import PolicyService from "@/core/services/car/PolicyService";

export default defineComponent({

    props: {
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    
    setup() {
        const store = useStore();
        
        const amendTypes = ["2", "4", "5"]

        const isOpen = computed(() => {
            return store.getters.getExtensionOpen
        })

        const policies = computed(() => {
            return store.getters.getpolicyDetails;
        });

        const extensions = computed(() => {
            return store.getters.getExtensions
        })

        // const extenstions = computed(() => {
        //     const data =  policies.value.car_lead_invoices
        //     .filter(x => amendTypes.includes(x.amend_type))
        //     .map((extension) => {
        //         const data = [];
        //         if(["2", "5"].includes(extension.amend_type)) {
        //                 data.push({
        //                     file: null,
        //                     type: `${extension.merchant_reference};CN`
        //                 },
        //                 {
        //                     file: null,
        //                     type: `${extension.merchant_reference};DN`
        //                 },
        //                 {
        //                     file: null,
        //                     type: `${extension.merchant_reference};Schedule`
        //                 })
        //         }

        //         if(extension.amend_type === "4") {
        //             data.push({
        //                     file: null,
        //                     type: `${extension.merchant_reference};DN`
        //                 },
        //                 {
        //                     file: null,
        //                     type: `${extension.merchant_reference};OC`
        //                 }
        //                 )
        //         }
        //         return data
        //     })

        //     return data.flat()
        // })

        const close = () => {
            PolicyService.setExtensionOpen(false);
        }

        return {
            extensions,
            isOpen,
            policies,
            close
        }
    },
})
</script>
