<template>
    <el-input
        id="customer-original-price"
        v-model="amount"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
        maxlength="12"
        ref="inputRef"
        @blur="fixValue"
        @focus="removeComma"
        @input="updateModel"
        :disabled="disabled"
    />
</template>

<script setup>
import { defineProps, defineEmits, defineExpose, watch, ref, onMounted, watchEffect } from 'vue'
import { useFixFigure } from '@/store/composable/Customer'
import { common } from '@/store/stateless/common'

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
        default: 0
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
})

const amount = ref(0)
const isFocus = ref(false)
const inputRef = ref()
const focus = () => {
    setTimeout(() => {
    inputRef.value.focus()
    }, 250);
}

const emit = defineEmits(['update:modelValue', 'change', 'press'])

onMounted(() => {
    if(typeof props.modelValue == 'number') {
        amount.value = props.modelValue
        if(amount.value) amount.value = common.formatCurrency(amount.value)
    }

    if(typeof props.modelValue == 'string' && (props.modelValue == 'NaN' || isNaN(props.modelValue))) {
        amount.value = 0
        if(amount.value) amount.value = common.formatCurrency(amount.value)

        let fixedValue = common.useFixFigure(0)
        emit("update:modelValue", fixedValue)
    }


    if(typeof props.modelValue == 'string' && props.modelValue != 'NaN') {
        amount.value = props.modelValue.replaceAll(',', '')
        if(typeof Number(amount.value) == 'number') {
            if(amount.value) amount.value = common.formatCurrency(amount.value)
        }
    }
})

watchEffect(() => {
    if(typeof props.modelValue == 'string' && props.modelValue !== '' && !isFocus.value) amount.value = common.formatCurrency(props.modelValue)
})

watch(() => props.modelValue, newValue => {
    if(newValue == '' || newValue == null) amount.value = 0

    
    if(newValue == 'NaN') {
        amount.value = 0
        let fixedValue = common.useFixFigure(0)
        emit("update:modelValue", fixedValue)
    }

    if(typeof newValue == 'number' && isFocus.value === false) {
        amount.value = newValue
        if(amount.value) amount.value = common.formatCurrency(amount.value)
    }
})

function updateModel() {
    const fixedValue = common.useFixFigure(amount.value)
    emit("update:modelValue", fixedValue)
    emit('press', fixedValue)
}

function removeComma() {
    isFocus.value = true
    let currentValue = amount.value
    if(!currentValue) currentValue = props.modelValue
    if(amount.value == 'NaN') amount.value = 0
    if(typeof currentValue == 'number') amount.value = currentValue
    if(amount.value) amount.value = currentValue.replaceAll(',', '')
    setTimeout(() => {
        inputRef.value.select()
    }, 100);
}

function fixValue() {
    isFocus.value = false
    let fixedValue = common.useFixFigure(amount.value)
    let formatedValue = common.formatCurrency(fixedValue)
    if(formatedValue == 'NaN') formatedValue = 0
    if(fixedValue == undefined) fixedValue = 0

    amount.value = formatedValue
    emit("update:modelValue", fixedValue)
    emit('change', fixedValue)
}


defineExpose({ focus })

</script>
