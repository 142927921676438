import { App, Component } from "vue";
import vcDatePicker from '@/components/reusables/vcDatePicker.vue'
import vcDatePickerTime from '@/components/reusables/vcDatePickerTime.vue'
import vcDateRange from '@/components/reusables/vcDateRange.vue'
import vcAmount from '@/components/reusables/vcAmount.vue'
import UpdateNameModal from '@/components/modals/forms/UpdateNameModal.vue'
import emiratesIDInput from '@/components/input/emiratesIDInput.vue'
import uploadDocuments from '@/components/modals/forms/uploadDocuments.vue'
import extensionItem from '@/components/reusables/extensionUploadDocumentItem.vue'

// New Component
import vcName from '@/components/reusables/NEW/vcName.vue'
import vcPhoneNumber from '@/components/reusables/NEW/vcPhoneNumber.vue'
import vcDatePickerv2 from '@/components/reusables/NEW/vcDatePicker.vue'
import vcDatePickerTimev2 from '@/components/reusables/NEW/vcDatePickerTime.vue'
import vcSockets from '@/components/reusables/NEW/vcSockets.vue'
import vcActions from '@/components/reusables/NEW/vcActions.vue'
import vcTables from '@/components/reusables/NEW/vcTables.vue'
import vcDropzone from '@/components/reusables/NEW/vcDropzone.vue'
import vcEmiratesID from '@/components/reusables/NEW/vcEmiratesID.vue'

import vcTablesNew from '@/components/reusables/NEW/Optimize/vcTablesNew.vue'

// Customer
import CustomerTabs from '@/components/NEW/Customer/CustomerTabs.vue'
import Accordion from '@/components/NEW/Customer/Accordion.vue'
import Title from '@/components/NEW/Customer/Accordion/Title.vue'
import Details from '@/components/NEW/Customer/Accordion/Details.vue'
import TaskDetails from '@/components/NEW/Customer/Accordion/TaskDetails.vue'
import Task from '@/components/NEW/Customer/Task.vue'

// Leads and Tasks
import ltFirst from '@/components/NEW/LeadsTasks/ltFirst.vue'
import ltSecond from '@/components/NEW/LeadsTasks/ltSecond.vue'
import ltThird from '@/components/NEW/LeadsTasks/ltThird.vue'
import ltFourth from '@/components/NEW/LeadsTasks/ltFourth.vue'
import ltFifth from '@/components/NEW/LeadsTasks/ltFifth.vue'

// Policy
import pFirst from '@/components/NEW/Policy/pFirst.vue'
import pSecond from '@/components/NEW/Policy/pSecond.vue'
import pThird from '@/components/NEW/Policy/pThird.vue'

// Invoice
import iFirst from '@/components/NEW/Invoice/iFirst.vue'
import iSecond from '@/components/NEW/Invoice/iSecond.vue'
import iThird from '@/components/NEW/Invoice/iThird.vue'


// Edit/View Policy Details
import UpdatePolicyDetails from '@/components/NEW/Policy/UpdatePolicyDetails.vue'
import UpdateCreditAndDebitNoteInformation from '@/components/NEW/Policy/UpdateCreditAndDebitNoteInformation.vue'
import UpdateDocuments from '@/components/NEW/Policy/UpdateDocuments.vue'
import UploadEndorsementDocuments from '@/components/NEW/Policy/UploadEndorsementDocuments.vue'
import EmailActivity from '@/components/NEW/Policy/EmailActivity.vue'

// modals
import Reusable from '@/components/NEW/Modals/Reusable.vue'
import EditName from '@/components/NEW/Modals/EditName.vue'
import EditEmail from '@/components/NEW/Modals/EditEmail.vue'
import EditPhone from '@/components/NEW/Modals/EditPhone.vue'
import EditOtherInfo from '@/components/NEW/Modals/EditOtherInfo.vue'
import TransferLeads from '@/components/NEW/Modals/TransferLeads.vue'
import AddNotes from '@/components/NEW/Modals/AddNotes.vue'
import AllDocuments from '@/components/NEW/Modals/AllDocuments.vue'
import DocumentLogs from '@/components/NEW/Modals/DocumentLogs.vue'
import EmailDetails from '@/components/NEW/Modals/EmailDetails.vue'
import ComposeEmail from '@/components/NEW/Modals/ComposeEmail.vue'
import RequestQuote from '@/components/NEW/Modals/RequestQuote.vue'
import DeleteDocument from '@/components/NEW/Modals/DeleteDocument.vue'
import CloseTask from '@/components/NEW/Modals/CloseTask.vue'
import MergeLead from '@/components/NEW/Modals/MergeLead.vue'
import Discrepancy from '@/components/NEW/Modals/Discrepancy.vue'
import CancelInitiate from '@/components/NEW/Modals/CancelInitiate.vue'
import CancelRequest from '@/components/NEW/Modals/CancelRequest.vue'
import CancelApprove from '@/components/NEW/Modals/CancelApprove.vue'
import RefundInitiate from '@/components/NEW/Modals/RefundInitiate.vue'
import RefundApprove from '@/components/NEW/Modals/RefundApprove.vue'
import TransferSale from '@/components/NEW/Modals/TransferSale.vue'
import TransferUnderwriter from '@/components/NEW/Modals/TransferUnderwriter.vue'
import DeleteMarketingCampaign from '@/components/NEW/Modals/DeleteMarketingCampaign.vue'
import PaymentWarning from '@/components/NEW/Modals/PaymentWarning.vue'
import PasswordReset from "@/components/NEW/Modals/PasswordReset.vue";

import UploadReceipt from '@/components/NEW/Modals/UploadReceipt.vue'
import InvoiceDetails from '@/components/NEW/Modals/InvoiceDetails.vue'
import QuickLead from '@/components/NEW/Modals/QuickLead.vue'
import InvoiceModal from '@/components/NEW/Modals/InvoiceModal.vue'


// drawers
import CustomerLog from '@/components/NEW/Drawers/CustomerLog.vue'
import ViewDetails from '@/components/NEW/Drawers/ViewDetails.vue'
import Tasks from '@/components/NEW/Drawers/Tasks.vue'
import Notes from '@/components/NEW/Drawers/Notes.vue'
import Policies from '@/components/NEW/Drawers/Policies.vue'
import Email from '@/components/NEW/Drawers/Email.vue'
import Documents from '@/components/NEW/Drawers/Documents.vue'
import ViewQuotes from '@/components/NEW/Drawers/ViewQuotes.vue'
import Sms from '@/components/NEW/Drawers/Sms.vue'
import LeadLog from '@/components/NEW/Drawers/LeadLog.vue'
import MarkFlowViewQuotes from '@/components/NEW/Drawers/MarkFlowViewQuotes.vue'

// partial drawers
import CustomerLogData from '@/components/NEW/Drawers/PartialLogs/CustomerLogData.vue'
import PolicyLogData from '@/components/NEW/Drawers/PartialLogs/PolicyLogData.vue'
import TaskData from '@/components/NEW/Drawers/PartialLogs/TaskData.vue'
import NotesData from '@/components/NEW/Drawers/PartialLogs/NotesData.vue'

import vcAmountV2 from '@/components/reusables/NEW/vcAmountV2.vue'

export function initSkyeComponent(app: App<Element>) {
    const data = [
        { key: 'vc-date-picker', component: vcDatePicker },
        { key: 'vc-date-picker-time', component: vcDatePickerTime },
        { key: 'vc-date-picker-range', component: vcDateRange },
        { key: 'vc-amount', component: vcAmount },
        { key: 'vue-input-mask', component: emiratesIDInput },
        { key: 'vc-update-name', component: UpdateNameModal },
        { key: 'vc-extension-update', component: uploadDocuments },
        { key: 'vc-extension-item', component: extensionItem },
        { key: 'vc-name', component: vcName },
        { key: 'vc-sockets', component: vcSockets },
        { key: 'vc-actions', component: vcActions },
        { key: 'vc-tables', component: vcTables },
        { key: 'vc-dropzone', component: vcDropzone },
        { key: 'vc-emirates-id', component: vcEmiratesID },
        { key: 'vc-phone-number', component: vcPhoneNumber },
        { key: 'vc-date-picker-v2', component: vcDatePickerv2 },
        { key: 'vc-date-picker-time-v2', component: vcDatePickerTimev2 },
        { key: 'vc-modal-edit-name', component: EditName },
        { key: 'vc-modal-edit-email', component: EditEmail },
        { key: 'vc-modal-edit-phone', component: EditPhone },
        { key: 'vc-modal-edit-other-info', component: EditOtherInfo },
        { key: 'vc-modal-customer-transfer-leads', component: TransferLeads },
        { key: 'vc-modal-customer-all-documents', component: AllDocuments },
        { key: 'vc-modal-customer-document-logs', component: DocumentLogs },
        { key: 'vc-modal-customer-email-details', component: EmailDetails },
        { key: 'vc-modal-customer-compose-email', component: ComposeEmail },
        { key: 'vc-modal-customer-request-quote', component: RequestQuote },
        { key: 'vc-modal-customer-delete-document', component: DeleteDocument },
        { key: 'vc-modal-customer-close-task', component: CloseTask },
        { key: 'vc-modal-customer-merge-lead', component: MergeLead },
        { key: 'vc-modal-policy-discrepancy', component: Discrepancy },
        { key: 'vc-modal-policy-cancel-initiate', component: CancelInitiate },
        { key: 'vc-modal-policy-cancel-request', component: CancelRequest },
        { key: 'vc-modal-policy-cancel-approve', component: CancelApprove },
        { key: 'vc-modal-policy-refund-initiate', component: RefundInitiate },
        { key: 'vc-modal-policy-refund-approve', component: RefundApprove },
        { key: 'vc-modal-policy-transfer-sale', component: TransferSale },
        { key: 'vc-modal-policy-transfer-underwriter', component: TransferUnderwriter },
        { key: 'vc-modal-reusable', component: Reusable },
        { key: 'vc-modal-payment-warning', component: PaymentWarning },
        { key: 'vc-modal-password-reset', component: PasswordReset },
        { key: 'vc-drawer-customer-log', component: CustomerLog },
        { key: 'vc-drawer-customer-add-notes', component: AddNotes },
        { key: 'vc-drawer-customer-view-details', component: ViewDetails },
        { key: 'vc-drawer-customer-tasks', component: Tasks },
        { key: 'vc-drawer-customer-notes', component: Notes },
        { key: 'vc-drawer-customer-policies', component: Policies },
        { key: 'vc-drawer-customer-log-data', component: CustomerLogData },
        { key: 'vc-drawer-view-task-data', component: TaskData },
        { key: 'vc-drawer-customer-notes-data', component: NotesData },
        { key: 'vc-drawer-customer-policy-logs-data', component: PolicyLogData },
        { key: 'vc-drawer-customer-email', component: Email },
        { key: 'vc-drawer-customer-documents', component: Documents },
        { key: 'vc-drawer-customer-view-quotes', component: ViewQuotes },
        { key: 'vc-drawer-customer-sms', component: Sms },
        { key: 'vc-drawer-customer-lead-log', component: LeadLog },
        { key: 'vc-customer-tabs', component: CustomerTabs },
        { key: 'vc-customer-accordion', component: Accordion },
        { key: 'vc-customer-accordion-title', component: Title },
        { key: 'vc-customer-accordion-details', component: Details },
        { key: 'vc-customer-accordion-task-details', component: TaskDetails },
        { key: 'vc-customer-accordion-task', component: Task },
        { key: 'vc-policy-update-policy-details', component: UpdatePolicyDetails },
        { key: 'vc-policy-update-credit-debit-note-information', component: UpdateCreditAndDebitNoteInformation },
        { key: 'vc-policy-update-documents', component: UpdateDocuments },
        { key: 'vc-policy-upload-endorsement-documents', component: UploadEndorsementDocuments },
        { key: 'vc-policy-email-activity', component: EmailActivity },
        { key: 'vc-leads-tasks-first', component: ltFirst },
        { key: 'vc-leads-tasks-second', component: ltSecond },
        { key: 'vc-leads-tasks-third', component: ltThird },
        { key: 'vc-leads-tasks-fourth', component: ltFourth },
        { key: 'vc-leads-tasks-fifth', component: ltFifth },
        { key: 'vc-policy-first', component: pFirst },
        { key: 'vc-policy-second', component: pSecond },
        { key: 'vc-policy-third', component: pThird },
        { key: 'vc-drawer-mark-flow-view-quotes', component: MarkFlowViewQuotes },
        { key: 'vc-modal-delete-marketing-campaign', component: DeleteMarketingCampaign },
        { key: 'vc-invoice-first', component: iFirst },
        { key: 'vc-invoice-second', component: iSecond },
        { key: 'vc-invoice-third', component: iThird },
        { key: 'vc-tables-new', component: vcTablesNew },
        { key: 'vc-modal-upload-receipt', component: UploadReceipt },
        { key: 'vc-modal-invoice-details', component: InvoiceDetails },
        { key: 'vc-modal-quick-lead', component: QuickLead },
        { key: 'vc-modal-invoice', component: InvoiceModal },
        { key: 'vc-amount-v2', component: vcAmountV2 },

    ]

    data.forEach(element => {
        app.component(element.key, element.component);
    })

}
