import store from "@/store";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { App, computed, ref } from "vue";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import TaskService from "./TaskService";
import { notif, leadsData } from '@/store/stateless/store';
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class CustomerService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    CustomerService.vueInstance = app;
  }

  public static getCustomerActivityLogs(payload) {
    store
      .dispatch(Actions.GET_CUSTOMER_ACTIVITY_LOGS, payload)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  public static searchCustomer(payload) {
    store
      .dispatch(Actions.SEARCH_CUSTOMERS, payload)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  public static getCustomerStatusLogs(payload) {
    store
      .dispatch(Actions.GET_CUSTOMER_STATUS_LOGS, payload)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  public static getCustomerDetails(store, customerId, reloadDom = true) {
    return new Promise((resolve, reject) => {
  
    const bodyParameters = {
      "customer_id": customerId
    }
    store
      .dispatch(Actions.GET_CUSTOMER_DETAILS, bodyParameters, reloadDom)
      .then((data) => {
        // console.log(data);
        resolve(data);

      })
      .catch((error) => {
        // console.log(error);
      });
    });
  }
  public static getCustomerDetailsNoRefresh(store, customerId) {
    return new Promise((resolve, reject) => {
  
    const bodyParameters = {
      "customer_id": customerId
    }
    store
      .dispatch(Actions.GET_CUSTOMER_DETAILS2, bodyParameters)
      .then((data) => {
        // console.log(data);
        resolve(data);

      })
      .catch((error) => {
        reject(error)
        // console.log(error);
      });
    });
  }

  public static async getCustomerDetailLeads(payload) {
    return await store.dispatch(Actions.GET_CUSTOMER_DETAILS, payload)
  }

  public static getCustomerStatusCount(store, bodyParameters) {
    return new Promise((resolve, reject) => {
      store
      .dispatch(Actions.GET_CUSTOMER_STATUS_COUNT, bodyParameters)
      .then((data) => {
        if(!bodyParameters.start_date) {
          leadsData.counts = data.data.status;
        }
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
      });
  }

  public static getCustomerLeadStatusCount(store, bodyParameters) {
    return new Promise((resolve, reject) => {
      store
      .dispatch(Actions.GET_LEAD_STATUS_COUNT_TOOLBAR, bodyParameters)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
      });
  }

  // Used for leads and tasks with filter.
  public static getCustomerLeadStatusFilterCount(store, bodyParameters) {
    return new Promise((resolve, reject) => {
      store
      .dispatch(Actions.GET_LEAD_STATUS_FILTER_COUNT, bodyParameters)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        // reject(error);
      });
      });
  }
  
  public static getDueDateTaskCount(store, bodyParameters) {
    store
      .dispatch(Actions.GET_DUE_DATE_TASK_COUNT, bodyParameters)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  public static getFilteredDueDateTaskCount(store, bodyParameters) {
    store
      .dispatch(Actions.GET_FILTERED_DUE_DATE_TASK_COUNT, bodyParameters)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  public static transferLeads(store, transferLeadsParam) {
    return new Promise((resolve, reject) => {
    store.dispatch(Actions.TRANSFER_LEADS, transferLeadsParam)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error)
      });
    });
  }
  
  public static getCustomerList(store, bodyParameters, index = 1) {
    bodyParameters.index = index;
    return new Promise<any>((resolve, reject) => {
    store
      .dispatch(Actions.LIST_CUSTOMER, bodyParameters)
      .then((data) => {
        resolve(data);
        let elem: any;
        // elem = $("#example");
        // console.log(store.getters.getCustomers);
        // setTimeout(() => {
        //     elem = $("#example");
        //     elem.DataTable().destroy();
        //     elem.DataTable().draw();
        // }, 3000);
        // CustomerService.setCustomerList(store,elem);
      })
      .catch((error) => {
        reject(error);

      });
    });
  }
  public static setCustomerList(store, elem) {

    // elem = $("#example");
    elem.DataTable().destroy();
    store.state.customerList = computed(() => {
      return store.getters.getCustomers;
    });

    // setTimeout(() => {
    //     elem = $("#example");
    //     elem.DataTable().draw();
    //     }, 3000);
  }
  public static getSkyeAgentList(store) {
    store
      .dispatch(Actions.GET_SALES_AGENT)
      .then((data) => { console.log(data); })
      .catch((error) => { console.log(error) })
  }
  public static importCustomerList(data) {
    return new Promise<void>((resolve, reject) => {
      store.dispatch(Actions.IMPORT_CUSTOMER_LIST, data)
        .then((data) => { 
      
          // Swal.fire({
          //   text: data.message,
          //   icon: "success",
          //   buttonsStyling: false,
          //   showConfirmButton: false,
          //   showCloseButton: true,
          //   showOkButton: false,
          // }).then(() => {
          // });
          resolve(data)
          notif.simple('Import', 'success', data.message);
        })
        .catch((error) => { 
          // Swal.fire({
          //   text: "Sorry, looks like there are some errors detected, please try again.",
          //   icon: "error",
          //   buttonsStyling: false,
          //   showConfirmButton: false,
          //   showCloseButton: true,
          //   showOkButton: false,
          // });
          notif.simple('Import', 'warning', 'Sorry, looks like there are some errors detected, please try again.', 0);
        })
    });
  }
  public static exportCustomer(store, bodyParameters) {
    store.dispatch(Actions.EXPORT_CUSTOMER_LIST, bodyParameters)
      .then((data) => { console.log(data); })
      .catch((error) => { console.log(error); })
  }
  public static getCalendarDetails(store) {
    store.dispatch(Actions.GET_CALENDAR_DETAILS)
      .then((data) => { console.log(data); })
      .catch((error) => { console.log(error); })
  }
  public static addLeadTask(store, taskBodyParam) {
    return new Promise<any>((resolve, reject) => {
      store.dispatch(Actions.ADD_LEAD_TASK, taskBodyParam)
        .then((data) => {
          //  
          // this.getCustomerDetails(store, taskBodyParam.customer_id); //commented for task issue

          // TaskService.getLeadTaskList(taskBodyParam); //commented get task details
          resolve(data);
        })
        .catch((error) => { reject(error) });
    });

  }
  public static addQuickCustomer(formData) {
    return new Promise<any>((resolve, reject) => {
    store
      .dispatch(Actions.ADD_QUICK_CUSTOMER, formData)
      .then((data) => {
        const addCustomerModalRef = ref<null | HTMLElement>(null);
        // Swal.fire({
        //   text: "Customer has been successfully created!",
        //   icon: "success",
        //   buttonsStyling: false,
        //   showConfirmButton: false,
        //   showCloseButton: true,
        //   showOkButton: false,
        // }).then(() => {
        // });
        hideModal(addCustomerModalRef.value);
        notif.simple('Quick Lead', 'success', 'Quick Lead has been successfully created!');
        resolve(data);
      })
      .catch((error) => {
        // console.log('data',data);

        // Swal.fire({
        //   text: "Sorry, looks like there are some errors detected, please try again.",
        //   icon: "error",
        //   buttonsStyling: false,
        //   showConfirmButton: false,
        //   showCloseButton: true,
        //   showOkButton: false,
        // });
        // notif.simple('Quick Lead', 'warning', 'Sorry, looks like there are some errors detected, please try again.', 0);
        // console.log(error)
        reject(error);
      });
    })
  }

  public static resetQlStatus() {
    store.commit(Mutations.SET_QL_STATUS, false)
  }

  public static mergeQuickLead(store, params) {
    store.dispatch(Actions.MERGE_QUICK_LEAD, params)
      .then((data) => {
        console.log(data);
        // Swal.fire({
        //   text: "Quick lead has been merged successfully !",
        //   icon: "success",
        //   buttonsStyling: false,
        //   confirmButtonText: "Ok, got it!",
        //   customClass: {
        //     confirmButton: "btn btn-primary",
        //   },
        // }).then(() => {
        // });
        this.getCustomerDetails(store, params.customer_id);
        notif.simple('Merge Lead', 'success', 'Quick lead has been merged successfully !');
      })
      .catch((error) => {
        console.log(error)
        // 
      })
  }
  public static addUserDetails(payload) {
    return new Promise<any>((res, rej) => {
      store.dispatch(Actions.ADD_USER_DETAILS, payload)
      .then((data) => {
        //  
        res(data)

      })
      .catch((err) => {
        // console.log(err)
        rej(err)
      })
    })
  }

  public static addBikeUserDetails(payload) {
    return new Promise<any>((res, rej) => {
      store.dispatch(Actions.ADD_BIKE_USER_DETAILS, payload)
      .then((data) => {
        //  
        res(data)

      })
      .catch((err) => {
        // console.log(err)
        rej(err)
      })
    })
  }
  public static EditBikeUserDetails(payload) {
    return new Promise<any>((res, rej) => {
      store.dispatch(Actions.EDIT_BIKE_USER_DETAILS, payload)
      .then((data) => {
        //
        res(data)

      })
      .catch((err) => {
        // console.log(err)
        rej(err)
      })
    })
  }

  public static clearIsError() {
    store.commit(Mutations.SET_EMAIL_IS_ERROR, false)
  }

  public static updateCustomerEmail(payload) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.UPDATE_CUSTOMER_EMAIL, payload)
        .then((data) => {
          //  
         
         resolve(data); 
        })
        .catch((error) => { 
          reject(error)
          // console.log(error); 
        });
    });
  }

  public static updateOtherContactInfo(payload) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.UPDATE_CUSTOMER_OTHER_CONTACT_INFO, payload)
        .then((data) => {
         resolve(data); 
        })
        .catch((error) => { 
          reject(error)
        });
    });
  }

  

  public static clearIsPhoneError() {
    store.commit(Mutations.SET_PHONE_IS_ERROR, false)
  }

  public static updateCustomerPhone(payload) {
    return new Promise<any>((resolve, reject) => {
      store.dispatch(Actions.UPDATE_CUSTOMER_PHONE, payload)
        .then((data) => {
          //  
         
         resolve(data);
        })
        .catch((error) => { reject(error) });
    });
  }

  public static sendOTP(payload) {
    return new Promise<any>((resolve, reject) => {
      store.dispatch(Actions.SEND_OTP, payload)
        .then((data) => {
         resolve(data);
        })
        .catch((error) => { 
          reject(error);
        });
    });
  }

  public static verifyOTPandUpdatePhoneNumber(payload) {
    return new Promise<any>((resolve, reject) => {
      store.dispatch(Actions.VERIFY_OTP_AND_UPDATE_PHONE_NUMBER, payload)
        .then((data) => {
         resolve(data);
        })
        .catch((error) => { 
          reject(error);
        });
    });
  }

  public static async checkExistNumber(payload) {
    return await store.dispatch(Actions.CHECK_EXIST_CUSTOMER, payload)
  }

  public static clearExistCustomer() {
      store.commit(Mutations.SET_EXIST_CUSTOMER, '')
      store.commit(Mutations.SET_QL_ENABLE, true)
  }

  public static setUploadTabLeadId(payload) {
    store.commit(Mutations.SET_UPLOAD_TAB_LEAD_ID, payload)
  }

  public static setUpdatePhoneNumberModal(payload) {
    store.commit(Mutations.SET_UPDATE_PHONE_MODAL, payload)
  }

  public static async updateCustomerName(payload) {
    return await store.dispatch(Actions.UPDATE_CUSTOMER_NAME, payload)
  }
  
}

export default CustomerService;
