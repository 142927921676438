
import { defineComponent, getCurrentInstance, toRef } from "vue";

export default defineComponent({
  name: "reusable",
  props: {
    isOpen: {
        type: Boolean,
        required: true
    },
    isCloseRefund: {
        type: Boolean,
        required: false
    },
    item: {
        type: Object,
        required: true
    },
    refundObj: {
        type: Object,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    message: {
        type: String,
        required: true
    },
    buttonRefundCancelText: {
        type: String,
        required: false,
        default: 'Cancel'
    },
    buttonCancelText: {
        type: String,
        required: false,
        default: 'No'
    },
    buttonOkext: {
        type: String,
        required: false,
        default: 'Yes'
    },
    isLoading: {
        type: Boolean,
        required: true,
        default: false
    }
  },

  setup(_, context) {
    const open = toRef(_, 'isOpen');

    const closeModal = () => {
      context.emit('close')
    }

    const submitCancel = (e, i) => {
      context.emit('submitCancel', e, i)
    }

    const submitOk = (e, i) => {
      context.emit('submitOk', e, i)
    }

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
          const buttonCancel:any = inst?.refs?.buttonCancel
          buttonCancel.focus()
      }, 500);
    }

    return {
      toFirst,
        open,
        closeModal,
        submitCancel,
        submitOk
    }
  }
  
});
