import ApiService from "@/core/services/ApiService";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";

@Module
export default class User extends VuexModule {
    salesAgents = []

    // Actions
    @Action
    async ['GET_SALES_AGENTS'](payload) {
        try {
            const response = await ApiService.post('/skye/agent-list-for-dom-new', payload)
            this.context.commit('SET_SALES_AGENTS', response.data.data)

            return response
        } catch (error) {
            return error
        }
    }

    // Mutations
    @Mutation
    ['SET_SALES_AGENTS']({ agents }) {
        this.salesAgents = agents
    }
}