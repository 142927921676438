import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import IDashboardOverview from "@/models/IDashboardOverview";
import store from "@/store";
import { parse } from "@vue/compiler-dom";

export interface DashboardInfo {
    dashboardUrl: DashboardUrlInfo;
    errors: Array<string>;
    
}
export interface IEmailTemplatesModel {
    value: number;
    label: string;
    id: number;
    subject: string;
}
export interface DashboardUrlInfo {

    getOverviewUrl: string;
    sendEmail: string;
    getEmailList:string;
}

const DASHBOARD_ANALYTICS_API = (ACTION_TYPE) => '/skye/dashboard/analytics/'+(ACTION_TYPE.split('_').join('-').toLowerCase());

@Module
export default class DashboardModule extends VuexModule implements DashboardInfo {
    dashboardUrl = {} as DashboardUrlInfo;
    totalSalesList = [] as any;//
    totalNewSalesList = [] as any;//
    totalWIPSalesList = [] as any;//
    totalRenewalSalesList = [] as any;
    totalLlrSalesList = [] as any;
    totalLrlSalesList = [] as any;
    lifetimeSalesList = [] as any;
    untouchedLeadsList = [] as any;
    totalRefundList = [] as any;
    totalRevenueList = [] as any;
    errors = [];
    statisticsLeadBySource = {};
    statisticsLeadByUTMSource = {};
    statisticsLeadByUTMMedium = {};
    statisticsDealsBySource = {};
    statisticsDealsByUTMSource = {};
    statisticsDealsByUTMMedium = {};
    policyIssueStat = [];
    policyCreatorStat = [];
    topProviders = [];
    topNationalities = [];
    topBrands = [];
    topVehicles = [];
    transactionOnline = [];
    transactionCod = [];
    transactionDirect = [];
    userLogs = [];
    leadsNew = [];
    leadsLost = [];
    leadsPending = [];
    policyData = [];
    quotedVehicle = [];
    policiesCoupon = [];
    closingRatio = [];
    smsStat = [];
    discountSummary = [];
    topSalesAgent = [];
    salesTarget = [];
    leadsTarget = [];
    isLeads = false;
    apps = [];
    salesPerformanceChart = {}; // object for sales performance chart
    leadBySourceCount = 0;
    leadByUTMSourceCount = 0;
    leadByUTMMediumCount = 0;
    dealBySourceCount = 0;
    dealByUTMSourceCount = 0;
    dealByUTMMediumCount = 0;
    accountsDashboard = {};
    loading = false;
    hasNoData = false;
    loadAll = false;
    tatol_revenue = 0;
    dashboardDateTitle = "";
    graphPaidVsOrganicLeads = {};
    countPaidVsOrganicLeads = 0;
    graphPaidVsOrganicDeals = {};
    countPaidVsOrganicDeals = 0;
    graphOrganicUTMLeads = {};
    countOrganicUTMLeads = 0;
    graphOrganicUTMDeals = {};
    countOrganicUTMDeals = 0;
    graphPaidUTMLeads = {};
    countPaidUTMLeads = 0;
    graphPaidUTMDeals = {};
    countPaidUTMDeals = 0;
    graphLeadSourceLeads = {};
    countLeadSourceLeads = 0;
    graphLeadSourceDeals = {};
    countLeadSourceDeals = 0;
    graphPaidVsOrganicLeadsTotal = {};
    graphPaidVsOrganicDealsTotal = {};
    graphOrganicUTMLeadsTotal = {};
    graphOrganicUTMDealsTotal = {};
    graphPaidUTMLeadsTotal = {};
    graphPaidUTMDealsTotal = {};
    graphLeadSourceLeadsTotal = {};
    graphLeadSourceDealsTotal = {};
    graphPaidUTMCampaignLeads = {};
    graphPaidUTMCampaignDeals = {};
    graphPaidVsOrganicWIPDeals = {};
    countLeadSourceLeadsDeleted = 0;
    graphPaidUTMCampaignLeadsDeleted = {};
    graphPaidUTMCampaignDealsWip = {};
    graphOrganicUTMCampaignDealsWip = {};
    graphPaidUTMContentDealsWip = {};
    graphOrganicUTMContentDealsWip = {};
    graphPaidUTMContentLeads = {};
    graphPaidUTMContentDeals = {};
    graphPaidUTMCampaignDealsRenewal = {};
    graphPaidUTMCampaignDealsLlr = {};
    graphPaidUTMContentDealsRenewal = {};
    graphPaidUTMContentDealsLlr = {};
    graphPaidUTMCampaignLeadsRenewal = {};
    graphPaidUTMCampaignLeadsLlr = {};
    graphPaidUTMContentLeadsRenewal = {};
    graphPaidUTMContentLeadsLlr = {};

    @Action
    [Actions.GET_DASHBOARD_OVERVIEW](payload) {
        payload['action'] = "GET_DASHBOARD_OVERVIEW";
        this.context.commit(Mutations.SET_LOADALL, true);
        return new Promise<any>((resolve, reject) => {
            this.dashboardUrl.getOverviewUrl = '/skye/dashboard/get-'+payload.type;
            ApiService.post(this.dashboardUrl.getOverviewUrl,payload)
                .then(({ data }) => {
                    if(data.data.type == 'total-sales')
                    {
                        this.context.commit(Mutations.SET_DASHBOARD_TOTAL, data.data);
                    }
                    if(data.data.type == 'total-new-sales')
                    {
                        this.context.commit(Mutations.SET_DASHBOARD_NEW, data.data);
                    }
                    if(data.data.type == 'total-renewal-sales')
                    {
                        this.context.commit(Mutations.SET_DASHBOARD_RENEWAL, data.data);
                    }
                    if(data.data.type == 'total-llr-sales')
                    {
                        this.context.commit(Mutations.SET_DASHBOARD_LLR, data.data);
                    }
                    if(data.data.type == 'total-lrl-sales')
                    {
                        this.context.commit(Mutations.SET_DASHBOARD_LRL, data.data);
                    }
                    if(data.data.type == 'lifetime-renewal-leads')
                    {
                        this.context.commit(Mutations.SET_DASHBOARD_LIFETIME, data.data);
                    }
                    if(data.data.type == 'total-untouched-leads')
                    {
                        this.context.commit(Mutations.SET_DASHBOARD_UNTOUCHED, data.data);
                    }
                    if(data.data.type == 'total-refund')
                    {
                        this.context.commit(Mutations.SET_DASHBOARD_REFUND, data.data);
                    }
                    if(data.data.type == 'total-revenue')
                    {
                        this.context.commit(Mutations.SET_DASHBOARD_REVENUE, data.data);
                    }
                    if(data.data.type == 'total-wip-sales')
                    {
                        this.context.commit(Mutations.SET_TOTAL_WIP_SALES, data.data);
                    }
                    

                    
                    resolve(data);
                    // this.context.commit(Mutations.SET_DOM_STATUS, true);
                    this.context.commit(Mutations.SET_LOADALL, false);
                })
                .catch(({ response }) => {

                    if(ApiService.errUndefinedCheck(response)){
                        this.context.commit(Mutations.SET_DASHBOARD_ERROR, response.data.errors);
                        reject(response);

                    }
                });
        });
    }



    @Action
    [Actions.GET_SALES_PERSON_INFO](payload) {
        this.context.commit(Mutations.SET_LOADALL, true);
        Promise.all([
            ApiService.post(DASHBOARD_ANALYTICS_API('GET_SALES_PERSON_INFO'), payload)
        ])
        .then((result) => {
            const API_RESPONSE = result[0].data.data;
            this.context.commit(Mutations.SET_DASHBOARD_TOP_SALES_AGENT, API_RESPONSE.topSalesPerson)
            this.context.commit(Mutations.SET_DASHBOARD_SALES_TARGET_SUMMARY, API_RESPONSE.salesTargetSummary)
            this.context.commit(Mutations.SET_DASHBOARD_LEADS_TARGET_SUMMARY, API_RESPONSE.leadTargetSummary)
            this.context.commit(Mutations.SET_LOADALL, false);
        })
    }

    /**
     * Called during dashboard analytics of 
     *   -sales-agent 
     * ``
     * 
     * TODO : No need of action call on salesa agent and team leader
     */
    @Action
    [Actions.GET_SALES_STATISTICS](payload) {
        this.context.commit(Mutations.SET_LOADALL, true);
        Promise.all([
            // ApiService.post('/skye/dashboard/graph/lead-sources', payload),
            // ApiService.post('/skye/dashboard/policy-creator-stats', payload),
            // ApiService.post('/skye/dashboard/policy-agent-stats', payload),
            // ApiService.post('/skye/dashboard/top-providers', payload),
            // ApiService.post('/skye/dashboard/top-nationality', payload),
            // ApiService.post('/skye/dashboard/top-brands', payload),
            // ApiService.post('/skye/dashboard/top-models', payload),
            // ApiService.post('/skye/dashboard/get-policies-sold-wow-coupon', payload),
            // ApiService.post('/skye/dashboard/top-discounts', payload),
            ApiService.post(DASHBOARD_ANALYTICS_API('GET_SALES_STATISTICS'), payload),
        ])
        .then((result) => {

            // this.context.commit(Mutations.SET_GRAPH_PAID_VS_ORAGNIC_LEADS, result[0].data.data.paid_vs_organic_leads)
            // this.context.commit(Mutations.SET_GRAPH_PAID_VS_ORAGNIC_DEALS, result[0].data.data.paid_vs_organic_deals)
            // this.context.commit(Mutations.SET_GRAPH_ORAGNIC_UTM_LEADS, result[0].data.data.organic_utm_source_leads)
            // this.context.commit(Mutations.SET_GRAPH_ORAGNIC_UTM_DEALS, result[0].data.data.organic_utm_source_deals)
            // this.context.commit(Mutations.SET_GRAPH_PAID_UTM_LEADS, result[0].data.data.paid_utm_source_leads)
            // this.context.commit(Mutations.SET_GRAPH_PAID_UTM_DEALS, result[0].data.data.paid_utm_source_deals)
            // this.context.commit(Mutations.SET_GRAPH_LEAD_SOURCE_LEADS, result[0].data.data.lead_source_leads)
            // this.context.commit(Mutations.SET_GRAPH_LEAD_SOURCE_DEALS, result[0].data.data.lead_source_deals)
            // this.context.commit(Mutations.SET_GRAPH_PAID_CAMPAIGN_LEADS, result[0].data.data.paid_utm_campaign_leads)
            // this.context.commit(Mutations.SET_GRAPH_PAID_CAMPAIGN_DEALS, result[0].data.data.paid_utm_campaign_deals)
            // this.context.commit(Mutations.SET_DASHBOARD_POLICY_CREATOR_STAT, result[1].data.data)
            // this.context.commit(Mutations.SET_DASHBOARD_POLICY_ISSUE_STAT, result[2].data.data)
            // this.context.commit(Mutations.SET_DASHBOARD_TOP_PROVIDERS, result[3].data.data.result)
            // this.context.commit(Mutations.SET_DASHBOARD_TOP_NATIONALITIES, result[4].data.data.result)
            // this.context.commit(Mutations.SET_DASHBOARD_TOP_BRANDS, result[5].data.data.result)
            // this.context.commit(Mutations.SET_DASHBOARD_TOP_VEHICLES, result[6].data.data.result)
            // this.context.commit(Mutations.SET_DASHBOARD_POLICIES_COUPON, result[7].data.data.result)
            // this.context.commit(Mutations.SET_DASHBOARD_DISCOUNT_SUMMARY, result[8].data.data.result)

            const API_RESPONSE = result[0].data.data;
            this.context.commit(Mutations.SET_GRAPH_PAID_VS_ORAGNIC_LEADS, API_RESPONSE.leadSourceGraph.paid_vs_organic_leads)
            this.context.commit(Mutations.SET_GRAPH_PAID_VS_ORAGNIC_DEALS, API_RESPONSE.leadSourceGraph.paid_vs_organic_deals)
            this.context.commit(Mutations.SET_GRAPH_ORAGNIC_UTM_LEADS, API_RESPONSE.leadSourceGraph.organic_utm_source_leads)
            this.context.commit(Mutations.SET_GRAPH_ORAGNIC_UTM_DEALS, API_RESPONSE.leadSourceGraph.organic_utm_source_deals)
            this.context.commit(Mutations.SET_GRAPH_PAID_UTM_LEADS, API_RESPONSE.leadSourceGraph.paid_utm_source_leads)
            this.context.commit(Mutations.SET_GRAPH_PAID_UTM_DEALS, API_RESPONSE.leadSourceGraph.paid_utm_source_deals)
            this.context.commit(Mutations.SET_GRAPH_LEAD_SOURCE_LEADS, API_RESPONSE.leadSourceGraph.lead_source_leads)
            this.context.commit(Mutations.SET_GRAPH_LEAD_SOURCE_DEALS, API_RESPONSE.leadSourceGraph.lead_source_deals)
            this.context.commit(Mutations.SET_GRAPH_PAID_CAMPAIGN_LEADS, API_RESPONSE.leadSourceGraph.paid_utm_campaign_leads)
            this.context.commit(Mutations.SET_GRAPH_PAID_CAMPAIGN_DEALS, API_RESPONSE.leadSourceGraph.paid_utm_campaign_deals)
            this.context.commit(Mutations.SET_DASHBOARD_POLICY_CREATOR_STAT, API_RESPONSE.policyCreatorStats)
            this.context.commit(Mutations.SET_DASHBOARD_POLICY_ISSUE_STAT, API_RESPONSE.policyAgentStats)

            this.context.commit(Mutations.SET_DASHBOARD_TOP_PROVIDERS, API_RESPONSE.topProviders.result)
            this.context.commit(Mutations.SET_DASHBOARD_TOP_NATIONALITIES, API_RESPONSE.topNationality.result)
            this.context.commit(Mutations.SET_DASHBOARD_TOP_BRANDS, API_RESPONSE.topBrands.result)
            this.context.commit(Mutations.SET_DASHBOARD_TOP_VEHICLES, API_RESPONSE.topModels.result)
            this.context.commit(Mutations.SET_DASHBOARD_POLICIES_COUPON, API_RESPONSE.getPoliciesSoldWowCoupon.result)
            this.context.commit(Mutations.SET_DASHBOARD_DISCOUNT_SUMMARY, API_RESPONSE.topDiscounts.result)

            this.context.commit(Mutations.SET_LEAD_LOADED, true)
            this.context.commit(Mutations.SET_LOADALL, false);
        }) 
    }

    @Action
    [Actions.GET_SALES_OTHERS](payload) {
      payload['action'] = "GET_SALES_OTHERS";
      this.context.commit(Mutations.SET_LOADALL, true);
        Promise.all([
            // ApiService.post('/skye/dashboard/user-recent-log', payload),
            // ApiService.post('/skye/dashboard/leads-new', payload),
            // ApiService.post('/skye/dashboard/leads-lost', payload),
            // ApiService.post('/skye/dashboard/leads-pending', payload),
            // ApiService.post('/skye/dashboard/list-cod-transactions', payload),
            // ApiService.post('/skye/dashboard/list-direct-transactions', payload),
            // ApiService.post('/skye/dashboard/get-policy-data', payload),
            // ApiService.post('/skye/dashboard/get-average-quoted-vehicle', payload),
            // ApiService.post('/skye/dashboard/get-closing-ratio', payload),
            // ApiService.post('/skye/dashboard/sms-stats', payload),
            ApiService.post(DASHBOARD_ANALYTICS_API('GET_SALES_OTHERS'), payload),
            // ApiService.post('/skye/dashboard/list-online-transactions', payload),
            
        ])
        .then((result) => {

            const API_RESPONSE = result[0].data.data;
            
            this.context.commit(Mutations.SET_DASHBOARD_USER_LOGS, API_RESPONSE.userRecentLog)
            this.context.commit(Mutations.SET_DASHBOARD_LEADS_NEW, API_RESPONSE.leadsNew)
            this.context.commit(Mutations.SET_DASHBOARD_LEADS_LOST, API_RESPONSE.leadsLost)
            this.context.commit(Mutations.SET_DASHBOARD_LEADS_PENDING, API_RESPONSE.leadsPending)
            this.context.commit(Mutations.SET_DASHBOARD_TRANSACTION_ONLINE, API_RESPONSE.listOnlineTransactions.result)
            this.context.commit(Mutations.SET_DASHBOARD_TRANSACTION_COD, API_RESPONSE.listCodTransactions.result)
            this.context.commit(Mutations.SET_DASHBOARD_TRANSACTION_DIRECT, API_RESPONSE.listDirectTransactions.result)
            this.context.commit(Mutations.SET_DASHBOARD_POLICY_DATA, API_RESPONSE.getPolicyData.result)
            this.context.commit(Mutations.SET_DASHBOARD_QUOTED_VEHICLE, API_RESPONSE.getAverageQuotedVehicle.result)
            this.context.commit(Mutations.SET_DASHBOARD_CLOSING_RATIO, API_RESPONSE.getClosingRatio.result)
            this.context.commit(Mutations.SET_DASHBOARD_SMS_STAT, API_RESPONSE.smsStats)
            this.context.commit(Mutations.SET_LOADALL, false);
        }) 
    }

    // This is called for Sales Agent, Sales Manager & Team Leader Dashboard
    @Action
    [Actions.GET_SALES_AGENT_MANAGER](payload) {
        this.context.commit(Mutations.SET_LOADALL, true);
        Promise.all([
            ApiService.post(DASHBOARD_ANALYTICS_API('GET_SALES_AGENT_MANAGER'), payload),
        ])
        .then((result) => {
            const API_RESPONSE = result[0].data.data;
            this.context.commit(Mutations.SET_DASHBOARD_TOP_SALES_AGENT, API_RESPONSE.topSalesPerson)
            this.context.commit(Mutations.SET_DASHBOARD_SALES_TARGET_SUMMARY, API_RESPONSE.salesTargetSummary)
            this.context.commit(Mutations.SET_DASHBOARD_POLICY_DATA, API_RESPONSE.getPolicyData.result)
            this.context.commit(Mutations.SET_LOADALL, false);

        }) 
    }

    // This is called for Underwriter Dashboard.
    @Action
    [Actions.GET_SALES_UNDERWRITER](payload) {
        payload['action'] = "GET_SALES_UNDERWRITER";
        this.context.commit(Mutations.SET_LOADALL, true);
        Promise.all([
            ApiService.post('/skye/dashboard/top-sales-person', payload),
            ApiService.post('/skye/dashboard/policy-agent-stats', payload),
            ApiService.post('/skye/dashboard/policy-creator-stats', payload),
        ])
        .then((result) => {
            this.context.commit(Mutations.SET_DASHBOARD_TOP_SALES_AGENT, result[0].data.data)
            this.context.commit(Mutations.SET_DASHBOARD_POLICY_ISSUE_STAT, result[1].data.data)
            this.context.commit(Mutations.SET_DASHBOARD_POLICY_CREATOR_STAT, result[2].data.data)
            this.context.commit(Mutations.SET_LOADALL, false);
        }) 
    }

    // This is called for Marketing Dashboard.
    @Action
    [Actions.GET_MARKETING_DASHBOARD](payload) {
        this.context.commit(Mutations.SET_LOADALL, true);
        Promise.all([
            ApiService.post('/skye/dashboard/graph/lead-sources', payload),
        ])
        .then((result) => {
            this.context.commit(Mutations.SET_GRAPH_PAID_VS_ORAGNIC_LEADS, result[0].data.data.paid_vs_organic_leads)
            this.context.commit(Mutations.SET_GRAPH_PAID_VS_ORAGNIC_DEALS, result[0].data.data.paid_vs_organic_deals)
            this.context.commit(Mutations.SET_GRAPH_ORAGNIC_UTM_LEADS, result[0].data.data.organic_utm_source_leads)
            this.context.commit(Mutations.SET_GRAPH_ORAGNIC_UTM_DEALS, result[0].data.data.organic_utm_source_deals)
            this.context.commit(Mutations.SET_GRAPH_PAID_UTM_LEADS, result[0].data.data.paid_utm_source_leads)
            this.context.commit(Mutations.SET_GRAPH_PAID_UTM_DEALS, result[0].data.data.paid_utm_source_deals)
            this.context.commit(Mutations.SET_GRAPH_LEAD_SOURCE_LEADS, result[0].data.data.lead_source_leads)
            this.context.commit(Mutations.SET_GRAPH_LEAD_SOURCE_DEALS, result[0].data.data.lead_source_deals)
            this.context.commit(Mutations.SET_GRAPH_PAID_CAMPAIGN_LEADS, result[0].data.data.paid_utm_campaign_leads)
            this.context.commit(Mutations.SET_GRAPH_PAID_CAMPAIGN_DEALS, result[0].data.data.paid_utm_campaign_deals)
            this.context.commit(Mutations.SET_GRAPH_ORGANIC_CAMPAIGN_DEALS, result[0].data.data.organic_utm_campaign_deals)
            this.context.commit(Mutations.SET_GRAPH_ORGANIC_CONTENT_DEALS, result[0].data.data.organic_utm_content_deals)
            this.context.commit(Mutations.SET_GRAPH_PAID_CONTENT_DEALS, result[0].data.data.paid_utm_content_deals)
            this.context.commit(Mutations.SET_GRAPH_PAID_CONTENT_LEADS, result[0].data.data.paid_utm_content_leads)
            
            this.context.commit(Mutations.SET_LEAD_LOADED, true)
            this.context.commit(Mutations.SET_LOADALL, false);
        }) 
    }

    @Action
    [Actions.GET_ACCOUNTS_DASHBOARD](payload) {
        this.context.commit(Mutations.SET_LOADALL, true);
        Promise.all([
            ApiService.post('/skye/dashboard/accounts-dashboard', payload),
        ])
        .then((result) => {
            this.context.commit(Mutations.SET_ACCOUNTS_DASHBOARD, result[0].data.data)
            this.context.commit(Mutations.SET_LOADALL, false);
        }) 
    }

    @Action
    [Actions.GET_DASHBOARD_LEADS_NEW](payload) {
        ApiService.post(`/skye/dashboard/leads-new?page=${payload.page}`, payload)
        .then((result) => {
            this.context.commit(Mutations.SET_DASHBOARD_LEADS_NEW, result.data.data)
        })
    }

    @Action
    [Actions.GET_APPS]() {
        ApiService.post(`/health/applications`, {})
        .then((result) => {
            this.context.commit(Mutations.SET_APPS, result.data.data.apps)
        })
    }

    @Action
    [Actions.GET_DASHBOARD_LEADS_LOST](payload) {
        ApiService.post(`/skye/dashboard/leads-lost?page=${payload.page}`, payload)
        .then((result) => {
            this.context.commit(Mutations.SET_DASHBOARD_LEADS_LOST, result.data.data)
        })
    }

    @Action
    [Actions.GET_DASHBOARD_LEADS_PENDING](payload) {
        ApiService.post(`/skye/dashboard/leads-pending?page=${payload.page}`, payload)
        .then((result) => {
            this.context.commit(Mutations.SET_DASHBOARD_LEADS_PENDING, result.data.data)
        })
    }

    @Action
    [Actions.GET_DASHBOARD_ONLINE_TRANSACTION](payload) {
        ApiService.post(`/skye/dashboard/list-online-transactions?page=${payload.page}`, payload)
        .then((result) => {
            this.context.commit(Mutations.SET_DASHBOARD_TRANSACTION_ONLINE, result.data.data.result)
        })
    }

    @Action
    [Actions.GET_DASHBOARD_DIRECT_TRANSACTION](payload) {
        ApiService.post(`/skye/dashboard/list-direct-transactions?page=${payload.page}`, payload)
        .then((result) => {
            this.context.commit(Mutations.SET_DASHBOARD_TRANSACTION_DIRECT, result.data.data.result)
        })
    }

    @Action
    [Actions.GET_DASHBOARD_COD_TRANSACTION](payload) {
        ApiService.post(`/skye/dashboard/list-cod-transactions?page=${payload.page}`, payload)
        .then((result) => {
            this.context.commit(Mutations.SET_DASHBOARD_TRANSACTION_COD, result.data.data.result)
        })
    }

    @Action
    [Actions.GET_DASHBOARD_GET_USER_LOGS](payload) {
        ApiService.post(`/skye/dashboard/user-recent-log?page=${payload.page}`, payload)
        .then((result) => {
            this.context.commit(Mutations.SET_DASHBOARD_USER_LOGS, result.data.data)
        })
    }
    
    @Mutation
    [Mutations.SET_DASHBOARD_ERROR](error) {
        const errors = [] as any;
        for (const key in error) {

            errors.push(error[key][0]);

        }
        this.errors = errors;   
    }
    @Mutation
    [Mutations.SET_DASHBOARD_LEADS_TARGET_SUMMARY](data) {
        this.leadsTarget = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_SALES_TARGET_SUMMARY](data) {
        this.salesTarget = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_TOP_SALES_AGENT](data) {
        this.topSalesAgent = data.result;
        this.loading = false;
        this.hasNoData = false;

        // data.resultPerformance.map(x => {
        //     const value = x.total_revenue.replace(',', '');
        //     if(parseFloat(value) > 0) {
        //         this.loading = true;
        //     } else {
        //         this.hasNoData = true
        //     }
        // });
        const tatol_revenue = data.resultPerformance.reduce((total, item) => {
            const value = item.total_revenue.replace(',', '');
            return (total += parseFloat(value))
        }, 0)

        this.tatol_revenue = tatol_revenue;

        if(tatol_revenue > 0) {
            this.loading = true;
        } else {
            this.hasNoData = true
        }

        // for sales performance chart
        let series = [];
        series = data.resultPerformance.map(x => {
            return parseFloat(x.total_revenue);
        });
        const lables = data.resultPerformance.map(x => {
            return x.agent_name
        });
        const salesAgentPerformance = {
            "series": series,
            "options": {
                "chart": {
                    "width": 600,
                    "height": 250,
                    "type": "pie"
                },
                "labels": lables,
                "responsive": [{
                    "breakpoint": 600,
                    "options": {
                        "chart": {
                            "width": 600,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        }
        this.salesPerformanceChart = salesAgentPerformance;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_DISCOUNT_SUMMARY](data) {
        this.discountSummary = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_SMS_STAT](data) {
        this.smsStat = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_CLOSING_RATIO](data) {
        this.closingRatio = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_POLICIES_COUPON](data) {
        this.policiesCoupon = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_QUOTED_VEHICLE](data) {
        this.quotedVehicle = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_POLICY_DATA](data) {
        this.policyData = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_LEADS_NEW](data) {
        this.leadsNew = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_LEADS_LOST](data) {
        this.leadsLost = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_LEADS_PENDING](data) {
        this.leadsPending = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_USER_LOGS](data) {
        this.userLogs = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_TRANSACTION_ONLINE](data) {
        this.transactionOnline = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_TRANSACTION_COD](data) {
        this.transactionCod = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_TRANSACTION_DIRECT](data) {
        this.transactionDirect = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_TOP_PROVIDERS](data) {
        this.topProviders = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_TOP_NATIONALITIES](data) {
        this.topNationalities = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_TOP_BRANDS](data) {
        this.topBrands = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_TOP_VEHICLES](data) {
        this.topVehicles = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_TOTAL](data) {
        this.totalSalesList = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_POLICY_ISSUE_STAT](data) {
        this.policyIssueStat = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_POLICY_CREATOR_STAT](data) {
        this.policyCreatorStat = data;
    }
    @Mutation
    [Mutations.SET_LEAD_LOADED](data) {
        this.isLeads = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_STATISTICS_LEAD_BY_SOURCE](data) {
        const leads = {
            "series": data.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "labels": data.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        }

        this.leadBySourceCount = data.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.statisticsLeadBySource = leads;
    }

    @Mutation
    [Mutations.SET_DASHBOARD_STATISTICS_LEAD_BY_UTMSOURCE](data) {
        const leads = {
            "series": data.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "labels": data.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        }

        this.leadByUTMSourceCount = data.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.statisticsLeadByUTMSource = leads;
    }

    @Mutation
    [Mutations.SET_DASHBOARD_STATISTICS_LEAD_BY_UTMMEDIUM](data) {
        const leads = {
            "series": data.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "labels": data.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        }

        this.leadByUTMMediumCount = data.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.statisticsLeadByUTMMedium = leads;
    }

    @Mutation
    [Mutations.SET_DASHBOARD_STATISTICS_DEAL_BY_SOURCE](data) {
        const deals = {
            "series": data.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "labels": data.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        }

        this.dealBySourceCount = data.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.statisticsDealsBySource = deals;
    }

    @Mutation
    [Mutations.SET_DASHBOARD_STATISTICS_DEAL_BY_UTMSOURCE](data) {
        const deals = {
            "series": data.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "labels": data.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        }

        this.dealByUTMSourceCount = data.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.statisticsDealsByUTMSource = deals;
    }
    
    @Mutation
    [Mutations.SET_DASHBOARD_STATISTICS_DEAL_BY_UTMMEDIUM](data) {
        const deals = {
            "series": data.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "labels": data.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        }

        this.dealByUTMMediumCount = data.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.statisticsDealsByUTMMedium = deals;
    }

    @Mutation
    [Mutations.SET_GRAPH_PAID_VS_ORAGNIC_LEADS](data) {
        
        const deals = {
            "series": (((typeof(data) !== 'undefined')) ? data.new.count.filter(x => x).map(x=> parseInt(x)) : 0),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        const dealsTotal = {
            "series": data.total.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.total.colors,
                "labels": data.total.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.countPaidVsOrganicLeads = data.new.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.graphPaidVsOrganicLeads = deals;
        this.graphPaidVsOrganicLeadsTotal = dealsTotal;
    }

    @Mutation
    [Mutations.SET_GRAPH_PAID_VS_ORAGNIC_DEALS](data) {
        const deals = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        const dealsTotal = {
            "series": data.total.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.total.colors,
                "labels": data.total.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        const wipDeals = {
            "series": data.wip.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.wip.colors,
                "labels": data.wip.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.countPaidVsOrganicDeals = data.new.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.graphPaidVsOrganicDeals = deals;
        this.graphPaidVsOrganicDealsTotal = dealsTotal;
        this.graphPaidVsOrganicWIPDeals = wipDeals;
    }

    @Mutation
    [Mutations.SET_GRAPH_ORAGNIC_UTM_LEADS](data) {
        const deals = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        const dealsTotal = {
            "series": data.total.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.total.colors,
                "labels": data.total.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.countOrganicUTMLeads = data.new.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.graphOrganicUTMLeads = deals;
        this.graphOrganicUTMLeadsTotal = dealsTotal;
    }

    @Mutation
    [Mutations.SET_GRAPH_ORAGNIC_UTM_DEALS](data) {
        const deals = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        const dealsTotal = {
            "series": data.total.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.total.colors,
                "labels": data.total.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.countOrganicUTMDeals = data.new.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.graphOrganicUTMDeals = deals;
        this.graphOrganicUTMDealsTotal = dealsTotal;
    }

    @Mutation
    [Mutations.SET_GRAPH_PAID_UTM_LEADS](data) {
        const deals = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        const dealsTotal = {
            "series": data.total.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.total.colors,
                "labels": data.total.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.countPaidUTMLeads = data.new.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.graphPaidUTMLeads = deals;
        this.graphPaidUTMLeadsTotal = dealsTotal;
    }

    @Mutation
    [Mutations.SET_GRAPH_PAID_UTM_DEALS](data) {
        const deals = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        const dealsTotal = {
            "series": data.total.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.total.colors,
                "labels": data.total.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.countPaidUTMDeals = data.new.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.graphPaidUTMDeals = deals;
        this.graphPaidUTMDealsTotal = dealsTotal;
    }

    @Mutation
    [Mutations.SET_GRAPH_LEAD_SOURCE_LEADS](data) {
        const deals = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        const dealsTotal = {
            "series": data.total.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.total.colors,
                "labels": data.total.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.countLeadSourceLeads = data.new.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.graphLeadSourceLeads = deals;
        this.graphLeadSourceLeadsTotal = dealsTotal;

        this.countLeadSourceLeadsDeleted = data.deleted.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);
    }

    @Mutation
    [Mutations.SET_GRAPH_LEAD_SOURCE_DEALS](data) {
        const deals = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        const dealsTotal = {
            "series": data.total.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.total.colors,
                "labels": data.total.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.countLeadSourceDeals = data.new.count.reduce((total, item) => {
            if(item != "") {
                return (total += parseInt(item));
            } else {
                return total += 0;
            }
          }, 0);

        this.graphLeadSourceDeals = deals;
        this.graphLeadSourceDealsTotal = dealsTotal;
    }

    @Mutation
    [Mutations.SET_GRAPH_PAID_CAMPAIGN_LEADS](data) {
        const leads = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom",
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMCampaignLeads = leads;

        const leadsDeleted = {
            "series": data.deleted.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.deleted.colors,
                "labels": data.deleted.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom",
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMCampaignLeadsDeleted = leadsDeleted;

        const leadsRenewal = {
            "series": data.renewal.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.renewal.colors,
                "labels": data.renewal.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom",
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMCampaignLeadsRenewal = leadsRenewal;

        const leadsLlr = {
            "series": data.llr.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.llr.colors,
                "labels": data.llr.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom",
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMCampaignLeadsLlr = leadsLlr;
    }

    @Mutation
    [Mutations.SET_GRAPH_PAID_CAMPAIGN_DEALS](data) {
        const deals = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMCampaignDeals = deals;

        const wipDeals = {
            "series": data.wip.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.wip.colors,
                "labels": data.wip.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMCampaignDealsWip = wipDeals;

        const renewalDeals = {
            "series": data.renewal.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.renewal.colors,
                "labels": data.renewal.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMCampaignDealsRenewal = renewalDeals;

        const llrDeals = {
            "series": data.llr.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.llr.colors,
                "labels": data.llr.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMCampaignDealsLlr = llrDeals;
    }

    @Mutation
    [Mutations.SET_GRAPH_PAID_CONTENT_DEALS](data) {
        const deals = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMContentDeals = deals;

        const wipDeals = {
            "series": data.wip.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.wip.colors,
                "labels": data.wip.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMContentDealsWip = wipDeals;

        const renewalDeals = {
            "series": data.renewal.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.renewal.colors,
                "labels": data.renewal.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMContentDealsRenewal = renewalDeals;

        const llrDeals = {
            "series": data.llr.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.llr.colors,
                "labels": data.llr.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMContentDealsLlr = llrDeals;
    }

    @Mutation
    [Mutations.SET_GRAPH_ORGANIC_CAMPAIGN_DEALS](data) {
        const wipDeals = {
            "series": data.wip.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.wip.colors,
                "labels": data.wip.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphOrganicUTMCampaignDealsWip = wipDeals;
    }

    @Mutation
    [Mutations.SET_GRAPH_ORGANIC_CONTENT_DEALS](data) {
        const wipDeals = {
            "series": data.wip.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.wip.colors,
                "labels": data.wip.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphOrganicUTMContentDealsWip = wipDeals;
    }

    @Mutation
    [Mutations.SET_GRAPH_PAID_CONTENT_LEADS](data) {
        const leads = {
            "series": data.new.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.new.colors,
                "labels": data.new.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMContentLeads = leads;

        const leadsRenewal = {
            "series": data.renewal.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.renewal.colors,
                "labels": data.renewal.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMContentLeadsRenewal = leadsRenewal;

        const leadsLlr = {
            "series": data.llr.count.filter(x => x).map(x=> parseInt(x)),
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": data.llr.colors,
                "labels": data.llr.names,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        };

        this.graphPaidUTMContentLeadsLlr = leadsLlr;
    }
    
    @Mutation
    [Mutations.SET_DASHBOARD_NEW](data) {
        this.totalNewSalesList = data;
    }
    @Mutation
    [Mutations.SET_TOTAL_WIP_SALES](data) {
        this.totalWIPSalesList = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_RENEWAL](data) {
        this.totalRenewalSalesList = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_LLR](data) {
        this.totalLlrSalesList = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_LRL](data) {
        this.totalLrlSalesList = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_LIFETIME](data) {
        this.lifetimeSalesList = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_UNTOUCHED](data) {
        this.untouchedLeadsList = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_REFUND](data) {
        this.totalRefundList = data;
    }
    @Mutation
    [Mutations.SET_DASHBOARD_REVENUE](data) {
        this.totalRevenueList = data;
    }
    @Mutation
    [Mutations.SET_APPS](data) {
        this.apps = data;
    }
    @Mutation
    [Mutations.SET_LOADALL](data) {
        this.loadAll = data;
    }

    @Mutation
    [Mutations.SET_ACCOUNTS_DASHBOARD](data) {
        this.accountsDashboard = data;
    }

    @Mutation
    [Mutations.SET_DASHBOARD_DATE_TITLE](data) {
        this.dashboardDateTitle = data;
    }

    
    get getTotalSalesList():IDashboardOverview  {
        return this.totalSalesList;
    }
    get getNewSalesList():IDashboardOverview {
        return this.totalNewSalesList;
    }
    get getWIPSalesList():IDashboardOverview {
        return this.totalWIPSalesList;
    }
    get getRenewalSalesList():IDashboardOverview  {
        return this.totalRenewalSalesList;
    }
    get getLlrSalesList():IDashboardOverview {
        return this.totalLlrSalesList;
    }
    get getLrlSalesList():IDashboardOverview {
        return this.totalLrlSalesList;
    }
    get getLifetimeSalesList():IDashboardOverview {
        return this.lifetimeSalesList;
    }
    get getUntouchedLeadsList():IDashboardOverview {
        return this.untouchedLeadsList;
    }
    get getTotalRefundList():IDashboardOverview {
        return this.totalRefundList;
    }
    get getTotalRevenueList():IDashboardOverview {
        return this.totalRevenueList;
    }

    get getStatisticsDealsBySource() {
        return this.statisticsDealsBySource
    }

    get getStatisticsDealsByUTMSource() {
        return this.statisticsDealsByUTMSource
    }

    get getStatisticsDealsByUTMMedium() {
        return this.statisticsDealsByUTMMedium
    }

    get getStatisticsLeadBySource() {
        return this.statisticsLeadBySource
    }

    get getStatisticsLeadByUTMSource() {
        return this.statisticsLeadByUTMSource
    }

    get getStatisticsLeadByUTMMedium() {
        return this.statisticsLeadByUTMMedium
    }

    get getLeadBySourceCount() {
        return this.leadBySourceCount
    }

    get getLeadByUTMSourceCount() {
        return this.leadByUTMSourceCount
    }

    get getLeadByUTMMediumCount() {
        return this.leadByUTMMediumCount
    }

    get getDealBySourceCount() {
        return this.dealBySourceCount
    }

    get getDealByUTMSourceCount() {
        return this.dealByUTMSourceCount
    }

    get getDealByUTMMediumCount() {
        return this.dealByUTMMediumCount
    }

    get getIsLeads() {
        return this.isLeads
    }

    get getPolicyIssueStat() {
        return this.policyIssueStat
    }

    get getPolicyCreatorStat() {
        return this.policyCreatorStat
    }

    get getTopProviders() {
        return this.topProviders
    }

    get getTopNationalities() {
        return this.topNationalities
    }

    get getTopBrands() {
        return this.topBrands
    }

    get getTopVehicles() {
        return this.topVehicles
    }

    get getTransactionOnline() {
        return this.transactionOnline
    }

    get getTransactionCod() {
        return this.transactionCod
    }

    get getTransactionDirect() {
        return this.transactionDirect
    }

    get getUserLogs() {
        return this.userLogs
    }

    get getLeadsNew() {
        return this.leadsNew
    }

    get getLeadsLost() {
        return this.leadsLost
    }

    get getLeadsPending() {
        return this.leadsPending
    }

    get getPolicyData() {
        return this.policyData
    }

    get getQuotedVehicle() {
        return this.quotedVehicle
    }

    get getPoliciesCoupon() {
        return this.policiesCoupon
    }

    get getClosingRatio() {
        return this.closingRatio
    }

    get getSmsStat() {
        return this.smsStat
    }

    get getDiscountSummary() {
        return this.discountSummary
    }

    get getTopSalesAgent() {
        return this.topSalesAgent
    }

    get getSalesTargetDashboard() {
        return this.salesTarget
    }

    get getLeadsTargetDashboard() {
        return this.leadsTarget
    }

    get getAppLinks() {
        return this.apps
    }

    get getSalesPerformanceChart() {
        return this.salesPerformanceChart
    }

    get getAccountsDashboardSummary() {
        return this.accountsDashboard
    }

    get getRevenueLoading() {
        return this.loading
    }

    get getLoadingAll() {
        return this.loadAll
    }

    get getDashboardDateTitle() {
        return this.dashboardDateTitle
    }

    get getHasNoData() {
        return this.hasNoData
    }

    get getGraphPaidVsOrganicLeads() {
        return this.graphPaidVsOrganicLeads
    }

    get getCountPaidVsOrganicLeads() {
        return this.countPaidVsOrganicLeads
    }

    get getGraphPaidVsOrganicDeals() {
        return this.graphPaidVsOrganicDeals
    }

    get getCountPaidVsOrganicDeals() {
        return this.countPaidVsOrganicDeals
    }

    get getGraphOrganicUTMLeads() {
        return this.graphOrganicUTMLeads
    }

    get getGraphOrganicUTMDeals() {
        return this.graphOrganicUTMDeals
    }

    get getGraphPaidUTMLeads() {
        return this.graphPaidUTMLeads
    }

    get getGraphPaidUTMDeals() {
        return this.graphPaidUTMDeals
    }

    get getGraphLeadSourceLeads() {
        return this.graphLeadSourceLeads
    }

    get getGraphLeadSourceDeals() {
        return this.graphLeadSourceDeals
    }

    get getCountLeadSourceLeads() {
        return this.countLeadSourceLeads
    }

    get getCountLeadSourceLeadsDeleted() {
        return this.countLeadSourceLeadsDeleted
    }

    get getCountLeadSourceDeals() {
        return this.countLeadSourceDeals
    }

    get getGraphPaidVsOrganicLeadsTotal() {
        return this.graphPaidVsOrganicLeadsTotal
    }

    get getGraphPaidVsOrganicDealsTotal() {
        return this.graphPaidVsOrganicDealsTotal
    }

    get getGraphPaidVsOrganicWIPDeals() {
        return this.graphPaidVsOrganicWIPDeals
    }

    get getGraphOrganicUTMLeadsTotal() {
        return this.graphOrganicUTMLeadsTotal
    }

    get getGraphOrganicUTMDealsTotal() {
        return this.graphOrganicUTMDealsTotal
    }

    get getGraphPaidUTMLeadsTotal() {
        return this.graphPaidUTMLeadsTotal
    }

    get getGraphPaidUTMDealsTotal() {
        return this.graphPaidUTMDealsTotal
    }

    get getGraphLeadSourceLeadsTotal() {
        return this.graphLeadSourceLeadsTotal
    }

    get getGraphLeadSourceDealsTotal() {
        return this.graphLeadSourceDealsTotal
    }

    get getGraphPaidUTMCampaignLeads() {
        return this.graphPaidUTMCampaignLeads
    }

    get getGraphPaidUTMCampaignLeadsDeleted() {
        return this.graphPaidUTMCampaignLeadsDeleted
    }

    get getGraphPaidUTMCampaignDeals() {
        return this.graphPaidUTMCampaignDeals
    }

    get getGraphPaidUTMCampaignDealsWip() {
        return this.graphPaidUTMCampaignDealsWip
    }

    get getGraphPaidUTMContentDealsWip() {
        return this.graphPaidUTMContentDealsWip
    }

    get getGraphOrganicUTMCampaignDealsWip() {
        return this.graphOrganicUTMCampaignDealsWip
    }

    get getGraphOrganicUTMContentDealsWip() {
        return this.graphOrganicUTMContentDealsWip
    }

    get getGraphPaidUTMContentLeads() {
        return this.graphPaidUTMContentLeads
    }

    get getGraphPaidUTMContentDeals() {
        return this.graphPaidUTMContentDeals
    }

    get getGraphPaidUTMCampaignDealsRenewal() {
        return this.graphPaidUTMCampaignDealsRenewal
    }

    get getGraphPaidUTMCampaignDealsLlr() {
        return this.graphPaidUTMCampaignDealsLlr
    }

    get getGraphPaidUTMContentDealsRenewal() {
        return this.graphPaidUTMContentDealsRenewal
    }

    get getGraphPaidUTMContentDealsLlr() {
        return this.graphPaidUTMContentDealsLlr
    }

    get getGraphPaidUTMCampaignLeadsRenewal() {
        return this.graphPaidUTMCampaignLeadsRenewal
    }

    get getGraphPaidUTMCampaignLeadsLlr() {
        return this.graphPaidUTMCampaignLeadsLlr
    }

    get getGraphPaidUTMContentLeadsRenewal() {
        return this.graphPaidUTMContentLeadsRenewal
    }

    get getGraphPaidUTMContentLeadsLlr() {
        return this.graphPaidUTMContentLeadsLlr
    }
}