
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import IQuotes from "@/models/Quotes";

import ILeadDetails from "@/models/car/LeadDetails";
import ILeadDriverDetails from "@/models/car/LeadDriverDetails";
import IEditQuotes from "@/models/car/EditQuotes";
import ILeadDriverQuotesList from "@/models/car/ILeadDriverQuotesList";
import IQuoteRequest from "@/models/IQuoteRequest";
import { IInsurancePolicyList } from "@/models/car/IInsurancePolicyList";
import { notif } from "@/store/stateless/store";
import IPaginationInfo from "@/models/IPaginationInfo";

export interface QuotesInfo {
    quotesUrl: QuotesUrlInfo;
    manufacturers: Array<IManufacturer>;
    models: Array<IModel>;
    trims: Array<ITrim>;
    countries: ICountries;
    dlCountries: ICountries;
    errors: Array<string>;
    duplicateData: IDuplicateData;
    quotesList: IQuotes;
    editQuote: IEditQuotes;
    leadDetails: ILeadDetails;
    estimatedValue: IEstimatedValue;
    leadDriverCompQuotesList: ILeadDriverQuotesList;
    leadDriverTplQuotesList: ILeadDriverQuotesList;
    carLead: ILeadDetails;
    policyList:IInsurancePolicyList;
    manualQuotesList:IManualQuotesList;
}
export interface IDuplicateData {
    status: boolean;
    message: string;
}
export interface IManufacturer {
    value: number;
    label: string;
}
export interface IBikeManufacturer {
    value: number;
    label: string;
}
export interface IModel {
    value: number;
    label: string;
    car: string;
}
export interface ICountries {
    value: number;
    label: string;
    rta_approved: number;
}
export interface QuotesUrlInfo {
    getManufacturers: string;
    getModels: string;
    getTrims: string;
    getCountryList: string;
    addQuotes: string;
    listQuotes: string;
    sendQuote: string;
    fetchQuote: string;
    isLeadRenewalPending:string;
    editQuoteUrl: string;
    getEstimatedValue: string;
    compareLeadCompQuoteList: string;
    compareLeadTplQuoteList: string;
    fetchCarLead: string;
    getInsurancePolicyListUrl:string;
    getManualQuotesUrl:string;
    sendManualQuote:string;
    addManualQuotes:string;
    downloadManualQuotes:string;
}
export interface ITrim {
    id: number;
    model_id: number;
    car_type: string;
    trim_level: string;
    cylinders: string;
    year_id: number;
    make_id: number;
    new_min: number;
    new_max: number;
    old_min: number;
    old_max: number;
    no_seats: number;
    created_at?: any;
    updated_at?: any;
    label?: string,
    value?: number
}

export interface IEstimatedValue {
    old_min: number;
    old_max: number;
}


interface keyVariable {
    [key: string] : string | number | undefined
}

export interface IManualQuotesList {
    id: number;
    customer_id: number;
    trim_level_id:number;
    car_year: string;
    make: string;
    model: string;
    model_details: string;
    policy_type: string;
    policy_type_text: string;
    filename: string;
    created_by: string;
    file_path:string;
    created_at?: any;
    updated_at?: any;
}

// Used for quote request listing
interface IQuoteRequestPayload {
    per_page: number | string,
    page: number,
}

interface IQuoteRequestDocs {
    id: number,
    lead_id: number,
    customer_id: number,
    // created_by_skye_user: object,
    // created_by_customer: object,
    insurance_provider_id: number,
    car_insurance_id: number,
    quote_request_id: number,
    manual_quotation_id: number,
    policy_type: number,
    title: number,
    filename: number,
    original_filename: number,
    created_at: number,
    updated_at: number,
    deleted_at: number,
    type: number,
    // insurance_policy: object,
}


@Module
export default class QuotesModule extends VuexModule implements QuotesInfo {
    quotesUrl = {} as QuotesUrlInfo;
    manufacturers = [] as Array<IManufacturer>;
    bikeManufacturers = [] as Array<IManufacturer>;
    models = [] as Array<IModel>;
    trims = [] as Array<ITrim>;
    dlCountries = {} as ICountries;
    countries = {} as ICountries;
    errors = [];
    duplicateData = {} as IDuplicateData;
    quotesList = {} as IQuotes;
    editQuote = {} as IEditQuotes;
    leadDetails = {} as ILeadDetails;
    leadDriverDetails = {} as ILeadDriverDetails;
    estimatedValue = {} as IEstimatedValue;
    leadDriverCompQuotesList = {} as ILeadDriverQuotesList;
    leadDriverTplQuotesList = {} as ILeadDriverQuotesList;
    carLead = {} as ILeadDetails;
    policyList = {} as IInsurancePolicyList;
    manualQuotesList = {} as IManualQuotesList;

    quoteRequestList = [] as Array<IQuoteRequest>;
    quoteRequestDocs = [] as Array<IQuoteRequestDocs>;
    paginationObj = {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 1,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: "",
        to: 1,
        total: 1,
    } as IPaginationInfo;
    quoteRequestId = 0;
    qrLoading = false;
    isManaulOrderLoading = false;

    quoteRequestPayload = {
        per_page: 50,
        page: 1,
    } as IQuoteRequestPayload;

    @Action
    [Actions.GET_MANUFACTURERS](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.getManufacturers = "skye/master/car-manufacturer";
            ApiService.post(this.quotesUrl.getManufacturers, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_MANUFACTURERS, data.data.manufacturers);
                    resolve(data);
                })
                .catch(({ response }) => {
                    // this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_BIKE_MANUFACTURERS](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.getManufacturers = "skye/master/bike-manufacturer";
            ApiService.post(this.quotesUrl.getManufacturers, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_BIKE_MANUFACTURERS, data.data.manufacturers);
                    resolve(data);
                })
                .catch(({ response }) => {
                    // this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_MODELS](payload) {
        return new Promise<any>((resolve, reject) => {// 
            this.quotesUrl.getModels = "/skye/master/car-models";
            ApiService.post(this.quotesUrl.getModels, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_MODELS, data.data.models);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.errors);
                    reject(response);
                });
        })
    }
    @Action
    [Actions.GET_TRIMS](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.getTrims = "/skye/master/car-trim-levels";
            ApiService.post(this.quotesUrl.getTrims, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_TRIMS, data.data.trim_level);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.errors);
                    reject(response);
                });
        })
    }
    @Action
    [Actions.GET_COUNTRIES]() {
        return new Promise<any>((resolve, reject) => {
            let payload: any;
            this.quotesUrl.getCountryList = "/skye/master/countries";
            ApiService.post(this.quotesUrl.getCountryList, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_COUNTRIES, data.data.countries);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.errors);
                    reject(response);
                });
        })
    }
    @Action
    [Actions.ADD_QUOTES](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.addQuotes = "/skye/car-leads/add-driver-details";
            ApiService.post(this.quotesUrl.addQuotes, payload)
                .then(({ data }) => {
                    
                    // this.context.commit(Mutations.SET_COUNTRIES,data.data.countries);
                    resolve(data);
                })
                .catch(({ response }) => {
                    
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);

                    if (response.data.message == 'Duplicate entry for Car and Driver details. This cannot be saved. Kindly refer to the existing record for further processing.') {
                        notif.simple("Please Note", "warning", response.data.message, 0);
                        this.duplicateData.message = response.data.message;
                        this.duplicateData.status = true;
                    }

                    reject(response);
                });
        })
    }
    @Action
    [Actions.GET_DRIVER_DETAILS_LIST](payload) {
        return new Promise<any>((resolve, reject) => {

            this.context.commit(Mutations.SET_QUOTES_DOM_STATUS, false);
            this.quotesUrl.listQuotes = "/skye/car-leads/list-driver-details";
            ApiService.post(this.quotesUrl.listQuotes, payload)
                .then(({ data }) => { // 
                    this.context.commit(Mutations.SET_LEAD_DRIVER_DETAILS_LIST, data.data.quotes);
                    resolve(data);
                    this.context.commit(Mutations.SET_QUOTES_DOM_STATUS, true);
                })
                .catch(({ response }) => { // 
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    reject(response);
                });
        })
    }
    @Action
    [Actions.SEND_QUOTE](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.sendQuote = "/skye/quote/send-quote";
            ApiService.post(this.quotesUrl.sendQuote, payload)
                .then(({ data }) => { // 
                    // this.context.commit(Mutations.SET_LEAD_DRIVER_DETAILS_LIST,data.data.quotes);
                    resolve(data);
                })
                .catch(({ response }) => { // 
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    reject(response);
                });
        })
    }
    @Action
    [Actions.GET_ESTIMATED_VALUE](payload) {

        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.getEstimatedValue = "/skye/estimated-value";
            ApiService.post(this.quotesUrl.getEstimatedValue, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_ESTIMATED_VALUE, data.data.value);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    reject(response);
                });
        })
    }
    @Action
    [Actions.FETCH_QUOTE](payload) {
        return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_INVOICE_DOM_STATUS, false);
            this.context.commit(Mutations.SET_EDIT_DRIVER_DETAILS_DOM, false);
            this.quotesUrl.fetchQuote = "/skye/car-leads/fetch-driver-details";
            ApiService.post(this.quotesUrl.fetchQuote, payload)
                .then(({ data }) => { // 
                    this.context.commit(Mutations.SET_EDIT_QUOTE, data.data.quote);
                    this.context.commit(Mutations.SET_EDIT_DRIVER_DETAILS_DOM, true);
                    this.context.commit(Mutations.SET_INVOICE_DOM_STATUS, true);
                    resolve(data);

                 
              
                })
                .catch(({ response }) => { // 
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    this.context.commit(Mutations.SET_EDIT_DRIVER_DETAILS_DOM, true);
                    reject(response);
                });
        })
    }

    @Action
    [Actions.IS_LEAD_RENEWAL_PENDING](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.isLeadRenewalPending = "/skye/car-leads/is-lead-renewal-pending";
            ApiService.post(this.quotesUrl.isLeadRenewalPending, payload)
                .then(({ data }) => { 
                    resolve(data);
                })
                .catch(({ response }) => { 
                    reject(response);
                });
        })
    }

    @Action
    [Actions.GET_LEAD_COMP_QUOTES_LIST](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.compareLeadCompQuoteList = "/skye/quote/compare-lead-comp-quotes";
            ApiService.post(this.quotesUrl.compareLeadCompQuoteList, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_LEAD_COPM_QUOTES_LIST, data.data.quotes);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    reject(response);
                });
        })
    }
    @Action
    [Actions.GET_LEAD_TPL_QUOTES_LIST](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.compareLeadTplQuoteList = "/skye/quote/compare-lead-tpl-quotes";
            ApiService.post(this.quotesUrl.compareLeadTplQuoteList, payload)
                .then(({ data }) => { // 
                    this.context.commit(Mutations.SET_LEAD_TPL_QUOTES_LIST, data.data.tpl_quotes);
                    resolve(data);
                })
                .catch(({ response }) => { // 
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    reject(response);
                });
        })
    }
    @Action
    [Actions.EDIT_QUOTES](payload) {
        return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            this.quotesUrl.editQuoteUrl = "/skye/car-leads/update-driver-details";//edit-driver-details
            ApiService.post(this.quotesUrl.editQuoteUrl, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_EDIT_QUOTE, data.data.quote);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                    if (response.data.message == 'Duplicate entry for Car and Driver details. This cannot be saved. Kindly refer to the existing record for further processing.') {
                        notif.simple("Please Note", "warning", response.data.message, 0);
                        this.duplicateData.message = response.data.message;
                        this.duplicateData.status = true;
                    }

                    const serverError = response.data.message;
                    if(serverError.includes('22003')) {
                        notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
                    }

                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    
                });
        })
    }
    @Action
    [Actions.EDIT_BIKE_USER_DETAILS](payload) {
        return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            this.quotesUrl.editQuoteUrl = "/skye/bike-leads/update-driver-details";//edit-driver-details
            ApiService.post(this.quotesUrl.editQuoteUrl, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_EDIT_QUOTE, data.data.quote);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    resolve(data);
                })
                .catch(({ response }) => {
                    if (response.data.message == 'Duplicate entry for Bike and Driver details. This cannot be saved. Kindly refer to the existing record for further processing.') {
                        notif.simple("Please Note", "warning", response.data.message, 0);
                        this.duplicateData.message = response.data.message;
                        this.duplicateData.status = true;
                    }

                    const serverError = response.data.message;
                    if(serverError.includes('22003')) {
                        notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
                    }

                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    reject(response);
                });
        })
    }
    @Action
    [Actions.FETCH_CAR_LEAD](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.fetchCarLead = "/skye/fetch-car-lead";
            ApiService.post(this.quotesUrl.fetchCarLead, payload)
                .then(({ data }) => { // 
                    this.context.commit(Mutations.SET_CAR_LEAD, data.data.lead);

                    resolve(data);
                })
                .catch(({ response }) => { // 
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    reject(response);
                });
        })

    }
    @Action
    [Actions.GET_INSURANCE_COMPANIES](payload){ // 
        return new Promise<any>((resolve, reject) => { // 
            this.quotesUrl.getInsurancePolicyListUrl = "skye/quote/list-insurance-policies";
            ApiService.post(this.quotesUrl.getInsurancePolicyListUrl,payload)
              .then(({ data }) => {// 
                this.context.commit(Mutations.SET_INSURANCE_POLICY_LIST, data.data.policies);
                resolve(data);
              })
              .catch(({ response }) => {
                this.context.commit(Mutations.SET_QUOTES_ERROR, response);
                reject(response);
              });
          }); 
    }

    @Action
    [Actions.GET_MANUAL_QUOTES](payload) {
            this.quotesUrl.getManualQuotesUrl = "skye/quote/list-manual-quotes";
            return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_MANUAL_QUOTES_DOM_STATUS,false);
            ApiService.post(this.quotesUrl.getManualQuotesUrl, payload)
            .then(({ data }) => {// 
            
            this.context.commit(Mutations.SET_MANUAL_QUOTES, data.data);
            // this.isApiCalled= false;
            this.context.commit(Mutations.SET_MANUAL_QUOTES_DOM_STATUS,true);
            resolve(data);
            })
            .catch(({ response }) => {
            this.context.commit(Mutations.SET_QUOTES_ERROR, response);
            // this.isApiCalled= false;
            reject(response);
            });
        });

    }
    @Action
    [Actions.SEND_MANUAL_QUOTE](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.sendManualQuote = "/skye/quote/send-manual-quote";
            ApiService.post(this.quotesUrl.sendManualQuote, payload)
                .then(({ data }) => { // 
                    resolve(data);
                })
                .catch(({ response }) => { // 
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    reject(response);
                });
        })
    }

    @Action
    [Actions.ADD_MANUAL_QUOTES](payload) {
        return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            this.context.commit(Mutations.SET_MANUAL_QUOTE_LOADING, true);
            this.quotesUrl.addManualQuotes = "/skye/quote/add-manual-quote";
            ApiService.post(this.quotesUrl.addManualQuotes, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_MANUAL_QUOTE_LOADING, false);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    resolve(data);
                })
                .catch((error) => {

                    this.context.commit(Mutations.SET_MANUAL_QUOTE_LOADING, false);
                    if(error.response.status === 422)
                    {
                        let data = '';
                        Object.entries(error.response.data.data).forEach(element => {
                            data += '<p>' + element[1] + '</p>'
                        });
                        
                        notif.simple('Please Note', 'warning', data, 0)

                        // notif.simple(error.response.data.message, 'warning', data, 0)
                        // this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, error.response);
                    }
                    if(error.response.status === 400)
                    {
                        notif.error('Generate Quote PDF Error','error', "Something went wrong!", 0)
                        // this.context.commit(Mutations.SET_INVOICE_MODAL);
                        // this.context.commit(Mutations.SET_INVOICE_MODAL_VALUE, {
                        //     customer_id:error.response.data.data.customer_id
                        // });
                    }
                    
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    
                    // this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);

                    reject(error);
                });
        })
    }

    @Action
    [Actions.DOWNLOAD_MANUAL_QUOTES](payload) {
        return new Promise<any>((resolve, reject) => {
            this.quotesUrl.sendManualQuote = "/skye/quote/download-manual-quote";
            ApiService.post(this.quotesUrl.sendManualQuote, payload)
                .then(({ data }) => { // 
                    resolve(data);
                })
                .catch(({ response }) => { // 
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    reject(response);
                });
        })
    }

    @Action
    [Actions.GET_QUOTE_REQUEST_LISTING](payload) {
        this.context.commit(Mutations.SET_QR_LOADING, true);
        return new Promise<any>((resolve, reject) => {
            const url = "/skye/car-leads/list-quote-request?page=" + payload.page;
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, []);
                    this.context.commit(Mutations.SET_QUOTE_REQUEST_LISTING, data.data);
                    this.context.commit(Mutations.SET_QR_LOADING, false);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response);
                    this.context.commit(Mutations.SET_QR_LOADING, false);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.GET_QUOTE_REQUEST_DOCS](payload) {
        return new Promise<any>((resolve, reject) => {
            const url = "/skye/documents/list-quote-request-document";
            // this.context.commit(Mutations.SET_QR_LOADING, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, []);
                    this.context.commit(Mutations.SET_QUOTE_REQUEST_DOCS, data.data);
                    // this.context.commit(Mutations.SET_QR_LOADING, false);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response);
                    // this.context.commit(Mutations.SET_QR_LOADING, false);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.GET_UPDATE_QUOTE_REQUEST_DOCUMENT](payload) {
        return new Promise<any>((resolve, reject) => {
            const url = "/skye/documents/update-quote-request-document";
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, []);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.APPROVE_QUOTE_REQUEST](payload) {
        this.context.commit(Mutations.SET_QR_LOADING, true);
        return new Promise<any>((resolve, reject) => {
            const url = "/skye/car-leads/approve-quote-request";
            ApiService.post(url, payload)
                .then(({ data }) => {
                    notif.simple("Quote Request", "success", "Quote is approved successfully!");
                    this.context.commit(Mutations.SET_QUOTES_ERROR, []);
                    this.context.commit(Mutations.SET_QR_LOADING, false);
                    resolve(data);
                })
                .catch(({ response }) => {
                    notif.error('Error',"Please select the mandatory information.")
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    this.context.commit(Mutations.SET_QR_LOADING, false);
                    reject(response);
                });
        });
    }

    @Action
    async [Actions.UPDATE_QUOTE_DOCS_NOTES](payload) {
        const url = "/skye/car-leads/update-quote-request-docs";
        return await ApiService.post(url, payload);
    }
    
    @Action
    [Actions.SEND_QUOTE_REQUEST](payload) {
        return new Promise<any>((resolve, reject) => {
            const url = "/skye/car-leads/quote-request";
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, []);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.DELETE_QR_DOCUMENT](payload) {
        return new Promise<any>((resolve, reject) => {
            const url = "/skye/documents/delete-quote-request-document";
            ApiService.post(url, payload)
                .then(({ data }) => {
                    //this.context.commit(Mutations.SET_QUOTES_ERROR, []);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.REQUEST_PAYMENT_LINK](payload) {
        this.context.commit(Mutations.SET_QR_LOADING, true);
        return new Promise<any>((resolve, reject) => {
            const url = "/skye/car-leads/payment-link-request";
            ApiService.post(url, payload)
                .then(({ data }) => {
                    notif.simple("Quotes", "success", "Payment link is requested successfully!");
                    this.context.commit(Mutations.SET_QUOTES_ERROR, []);
                    this.context.commit(Mutations.SET_QR_LOADING, false);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    this.context.commit(Mutations.SET_QR_LOADING, false);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.SEND_PAYMENT_LINK](payload) {
        this.context.commit(Mutations.SET_QR_LOADING, true);
        return new Promise<any>((resolve, reject) => {
            const url = "/skye/car-leads/payment-link-sent";
            ApiService.post(url, payload)
                .then(({ data }) => {
                    notif.simple("Quotes", "success", "Payment link is sent successfully!");
                    this.context.commit(Mutations.SET_QUOTES_ERROR, []);
                    this.context.commit(Mutations.SET_QR_LOADING, false);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                    this.context.commit(Mutations.SET_QR_LOADING, false);
                    reject(response);
                });
        });
    }

    @Action
    async[Actions.DELETE_QUOTE_REQUEST](payload) { 
        const url = "/skye/car-leads/delete-quote-request";
        try {
            const data = await ApiService.post(url, payload);
            return data;
        } catch (err) {
            return err;
        }
    }

    @Mutation
    [Mutations.SET_QR_LOADING](payload): void {
        this.qrLoading = payload;
    }

    @Mutation
    [Mutations.SET_MANUFACTURERS](payload): void {
        this.manufacturers = payload;
    }
    @Mutation
    [Mutations.SET_BIKE_MANUFACTURERS](payload): void {
        this.bikeManufacturers = payload;
    }
    @Mutation
    [Mutations.SET_MODELS](payload): void {
        this.models = payload;
    }
    @Mutation
    [Mutations.SET_TRIMS](payload): void {
        this.trims = payload;
    }
    @Mutation
    [Mutations.SET_COUNTRIES](payload): void {
        this.countries = payload;
        this.dlCountries = payload;
    }

    @Mutation
    [Mutations.SET_QUOTES_ERROR](error) {
        for (const key in error) {
            this.errors = error[key];
        }
    }
    @Mutation
    [Mutations.SET_LEAD_DRIVER_DETAILS_LIST](payload): void {// 
        this.leadDetails = payload;
        this.leadDriverDetails = payload[0].lead_driver_details
    }
    @Mutation
    [Mutations.SET_EDIT_QUOTE](payload): void {
        this.editQuote = payload;
    }
    @Mutation
    [Mutations.UPDATE_EDIT_QUOTE_EMAIL](payload): void {
        
        this.editQuote.customer.email = payload.customer.email;
    }
    @Mutation
    [Mutations.UPDATE_EDIT_QUOTE_PHONE](payload): void {
        // 
        this.editQuote.customer.phone_number = payload.customer.phone_number;
        // this.editQuote.customer.phone_country_code = payload.customer.phone_country_code;
    }
    @Mutation
    [Mutations.SET_ESTIMATED_VALUE](payload): void {

        this.estimatedValue = payload;
        this.editQuote.car_value = payload.old_max;
    }
    @Mutation
    [Mutations.SET_LEAD_COPM_QUOTES_LIST](payload): void {
        // 
        this.leadDriverCompQuotesList = payload;
    }
    @Mutation
    [Mutations.SET_LEAD_TPL_QUOTES_LIST](payload): void {
        // 
        this.leadDriverTplQuotesList = payload;
    }
    @Mutation
    [Mutations.SET_CAR_LEAD](payload): void {
        this.carLead = payload;
    }

    @Mutation
    [Mutations.SET_INSURANCE_POLICY_LIST](payload) { 
        this.policyList = payload;
    }

    @Mutation
    [Mutations.SET_MANUAL_QUOTES](payload) {
        this.manualQuotesList = payload;
    }

    @Mutation
    [Mutations.SET_QUOTE_REQUEST_LISTING](payload) {
        this.quoteRequestList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_QUOTE_REQUEST_DOCS](payload) {
        this.quoteRequestDocs = payload.documents;
        this.quoteRequestId = payload.quote_request_id;
    }

    @Mutation
    [Mutations.SET_MANUAL_QUOTE_LOADING](payload) {
        this.isManaulOrderLoading = payload;
    }

    
    get getQuoteRequestId() : any {
        return this.quoteRequestId
    }

    get getEstimatedCarValue(): IEstimatedValue {
        console.log('payload', this.estimatedValue)
        return this.estimatedValue;
    }

    get getManufacturersList(): Array<IManufacturer> {
        return this.manufacturers;
    }
    get getBikeManufacturersList(): Array<IManufacturer> {
        return this.bikeManufacturers;
    }

    get getModelsList(): Array<IModel> {
        return this.models;
    }

    get getTrimDetails(): Array<ITrim> {
        return this.trims;
    }

    // get getNewTrims() {
    //     return (vehicle_type = 1) => {
    //         let trim = <Array<keyVariable>>[{ label: `${vehicle_type == 1 ? 'Car Trim' : 'Trim'}`, value: 0 }]
    //         if(this.trims.length > 0) trim = this.trims.map((x: ITrim) => { return { label: x.label, value: x.value } })

    //         const exist = trim.some(x => x.value === 0)
    //         if(!exist) trim.unshift({ label: `${vehicle_type == 1 ? 'Car Trim' : 'Trim'}`, value: 0 })

            
    //         return trim
    //     }
    // }

    // get getNewModels() {
    //     let model = <Array<keyVariable>>[{ label: 'Car Model', value: 0 }]
    //     if(this.models.length > 0) model = this.models.map((x: IModel) => { return { label: x.label, value: x.value } })

    //     const exist = model.some(x => x.value === 0)
    //     if(!exist) model.unshift({ label: 'Car Model', value: 0 })

        
    //     return model
    // }

    // get getNewManufacturers() {
    //     let manufacturer = <Array<keyVariable>>[{ label: 'Car Make', value: 0 }]
    //     if(this.manufacturers.length > 0) manufacturer = this.manufacturers.map((x: IManufacturer) => { return { label: x.label, value: x.value } })

    //     const exist = manufacturer.some(x => x.value === 0)
    //     if(!exist) manufacturer.unshift({ label: 'Car Make', value: 0 })

        
    //     return manufacturer
    // }

    get getQuotesErrors(): Array<string> {
        return this.errors;
    }
    get getDuplicateQuotesErrorMessage(): IDuplicateData {
        return this.duplicateData;
    }

    get getEditQuote(): IEditQuotes {
        return this.editQuote;
    }

    get getCarLead(): ILeadDetails {
        return this.carLead;
    }
    get getCountry(): ICountries {
        return this.countries;
    }
    get getDlCountry(): ICountries {
        return this.dlCountries;
    }
    get getInsuranceCompaniesList(): IInsurancePolicyList {
        return this.policyList;
    }
    get getLeadDetailsQuotes() {
        return this.leadDetails;
    }

    get getquoteRequestPayload() {
        return this.quoteRequestPayload;
    }

    get getquoteRequestList() {
        return this.quoteRequestList;
    }

    get getPagination() {
        return this.paginationObj;
    }

    get getQuoteRequestDocument() {
        return this.quoteRequestDocs;
    }

    get getQrLoading() {
        return this.qrLoading;
    }

    get getManaulOrderLoading() {
        return this.isManaulOrderLoading;
    }

}