
import { defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import { notif, updateModal } from '@/store/stateless/store'
import router from '@/router'
import CustomerService from '@/core/services/car/CustomerService'
import { useStore } from "vuex";

export default defineComponent({
    props: {
        name: {
            type: String,
            required: true
        }
    },
    setup(_) {
        const customer_id = ref(router.currentRoute.value.params.customerId)
        const store = useStore();
        const inst = getCurrentInstance()

        onMounted(() => {
            updateModal.name = _.name

            setTimeout(() => {
                const nameRef:any = inst?.refs?.nameRef
                nameRef.focus()
            }, 500);
            
        })

        const close = () => {
            updateModal.open = false
            updateModal.name = ''
        }

        const updateCustomerName = async() => {
            const params = {
                customer_id: customer_id.value,
                name: updateModal.name
            }

            try {
                const data = await CustomerService.updateCustomerName(params);
                if(data) {
                    notif.simple("Customer Name Updated", "success", "You have successfully update customer name")
                    CustomerService.getCustomerDetails(store, params.customer_id)
                    close()
                }
            } catch (err) {
                notif.simple("Error", "warning", "Please check your connection")
            }

        }

        return {
            updateModal,
            customer_id,
            updateCustomerName,
            close
        }
    },
})
