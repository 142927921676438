import store from "@/store"

class Quotes {

    public static async fetchQuote(payload) {
        try {
            const response = await store.dispatch('FETCH_QUOTE', payload)
            
            return response
        } catch (error) {
            return error
        }
    }

    public static async getManufacturers(payload) {
        return await store.dispatch('GET_MANUFACTURERS', payload)
    }

    public static async getBikeManufacturers(payload) {
        return await store.dispatch('GET_BIKE_MANUFACTURERS', payload)
    }

    public static async getModels(payload) {
        return await store.dispatch('GET_MODELS', payload)
    }

    public static async getTrims(payload) {
        return await store.dispatch('GET_TRIMS', payload)
    }

    public static async getEstimatedValue(payload) {
        return await store.dispatch('GET_ESTIMATED_VALUE', payload)
    }

    public static resetValues() {
        store.commit('SET_MANUFACTURERS', [])
        store.commit('SET_MODELS', [])
        store.commit('SET_TRIMS', [])
    }

    public static resetModelValues() {
        store.commit('SET_MODELS', [])
        store.commit('SET_TRIMS', [])
    }

    public static resetTrimValues() {
        store.commit('SET_TRIMS', [])
    }

    public static async getCountries() {
        return await store.dispatch('GET_COUNTRIES')
    }
}

export default Quotes