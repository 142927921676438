import ApiService from "@/core/services/ApiService";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";

interface keyVariable {
    [key: string] : string | number | undefined
}

@Module
export default class Quote extends VuexModule {
    quotes = []
    manufacturers = []
    bikeManufacturers = []
    models = []
    trims = []
    countries = []
    dlCountries = []
    estimatedValue = {}

    // Actions
    @Action
    async ['FETCH_QUOTE'](payload) {
        try {
            const response = await ApiService.post('/skye/car-leads/fetch-driver-details', payload)
            this.context.commit('SET_QUOTE', response.data.data)

            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_MANUFACTURERS'](payload) {
        try {
            const response = await ApiService.post('/skye/master/car-manufacturer', payload)
            this.context.commit('SET_MANUFACTURERS', response.data.data)
            
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_BIKE_MANUFACTURERS'](payload) {
        try {
            const response = await ApiService.post('/skye/master/bike-manufacturer', payload)
            this.context.commit('SET_BIKE_MANUFACTURER', response.data.data)

            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_MODELS'](payload) {
        try {
            const response = await ApiService.post('/skye/master/car-models', payload)
            this.context.commit('SET_MODELS', response.data.data)

            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_TRIMS'](payload) {
        try {
            const response = await ApiService.post('/skye/master/car-trim-levels', payload)
            this.context.commit('SET_TRIMS', response.data.data)

            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_ESTIMATED_VALUE'](payload) {
        try {
            const response = await ApiService.post('/skye/estimated-value', payload)
            this.context.commit('SET_ESTIMATED_VALUE', response.data.data)

            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_COUNTRIES']() {
        try {
            const response = await ApiService.post('/skye/master/countries', {})
            this.context.commit('SET_COUNTRIES', response.data.data)

            return response
        } catch (error) {
            return error
        }
    }

    // Mutations
    @Mutation
    ['SET_QUOTE']({ quote }) {
        this.quotes = quote
    }

    @Mutation
    ['SET_MANUFACTURERS']({ manufacturers }) {
        if(manufacturers != undefined)
        this.manufacturers = manufacturers
    }

    @Mutation
    ['SET_BIKE_MANUFACTURER']({ manufacturers }) {
        if(manufacturers != undefined)
        this.bikeManufacturers = manufacturers
    }

    @Mutation
    ['SET_MODELS']({ models }) {
        if(models != undefined)
        this.models = models
    }

    @Mutation
    ['SET_TRIMS']({ trim_level }) {
        if(trim_level != undefined)
        this.trims = trim_level
    }

    @Mutation
    ['SET_ESTIMATED_VALUE']({ value }) {
        this.estimatedValue = value
    }

    @Mutation
    ['SET_COUNTRIES']({ countries }) {
        this.countries = countries
        this.dlCountries = countries
    }

    // get
    get getNewTrims() {
        return (vehicle_type = 1) => {
            let trim = <Array<keyVariable>>[{ label: `${vehicle_type == 1 ? 'Car Trim' : 'Trim'}`, value: 0 }]
            if(this.trims && this.trims.length > 0) trim = this.trims.map((x: keyVariable) => { return { label: x.label, value: x.value } })

            const exist = trim.some(x => x.value === 0)
            if(!exist) trim.unshift({ label: `${vehicle_type == 1 ? 'Car Trim' : 'Trim'}`, value: 0 })

            
            return trim
        }
    }

    get getNewModels() {
        let model = <Array<keyVariable>>[{ label: 'Car Model', value: 0 }]
        if(this.models && this.models.length > 0) model = this.models.map((x: keyVariable) => { return { label: x.label, value: x.value } })

        const exist = model.some(x => x.value === 0)
        if(!exist) model.unshift({ label: 'Car Model', value: 0 })

        
        return model
    }

    get getNewManufacturers() {
        let manufacturer = <Array<keyVariable>>[{ label: 'Car Make', value: 0 }]
        if(this.manufacturers && this.manufacturers.length > 0) manufacturer = this.manufacturers.map((x: keyVariable) => { return { label: x.label, value: x.value } })

        const exist = manufacturer.some(x => x.value === 0)
        if(!exist) manufacturer.unshift({ label: 'Car Make', value: 0 })

        
        return manufacturer
    }
}