import store from "@/store"

class Customer {

    public static async getCustomerDetailLeads(payload) {
        return await store.dispatch('GET_CUSTOMER_DETAILS', payload)
    }

    public static async getCustomerDetailLeadsV2(payload) {
        return await store.dispatch('GET_CUSTOMER_DETAILS_V2', payload)
    }
    public static async getCarLead(payload) {
        return await store.dispatch('GET_CAR_LEAD', payload)
    }
    
    public static clearExistCustomer() {
        store.commit('SET_EXIST_PHONE_NUMBER', '')
        store.commit('SET_QL_ENABLE', true)
    }

    public static async addUserDetails(payload) {
        return await store.dispatch('ADD_USER_DETAILS', payload)
    }

    public static async checkExistNumber(payload) {
        return await store.dispatch('CHECK_EXIST_PHONE_NUMBER', payload)
    }

    public static async withGlobal(payload) {
        return await store.dispatch('WITH_GLOBAL', payload)
    }

    public static async getCustomerActivityLogs(payload) {
        return await store.dispatch('GET_CUSTOMER_ACTIVITY_LOG', payload)
    }

    public static async getCustomerStatusLogs(payload) {
        return await store.dispatch('GET_CUSTOMER_STATUS_LOG', payload)
    }

    public static async getAllTaskByLeadAndCustomerId(payload) {
        return await store.dispatch('GET_ALL_TASK_BY_LEAD_AND_CUSTOMER_ID', payload)
    }

    public static async getAllNotesByLeadId(payload) {
        return await store.dispatch('GET_ALL_NOTES_BY_LEAD_ID', payload)
    }

    public static async getAllPoliciesByLeadAndCustomerId(payload) {
        store.commit('SET_LEAD_POLICIES', {policies: []})

        return await store.dispatch('GET_LEAD_POLICIES', payload)
    }

    public static async getDocumentLogList(payload) {
        return await store.dispatch('GET_DOCUMENT_LOG', payload)
    }

    public static async addDocumentLog(payload) {
        return await store.dispatch('ADD_DOCUMENT_LOG', payload)
    }
    
    public static async getPolicyLogList(payload) {
        return await store.dispatch('GET_POLICY_LOG', payload)
    }
    
    public static async getEmailList(payload) {
        return await store.dispatch('GET_EMAILS', payload)
    }
    
    public static async getManualQuotes(payload) {
        return await store.dispatch('GET_MANUAL_QUOTES', payload)
    }
    
    public static async getSms(payload) {
        return await store.dispatch('GET_SMS', payload)
    }
    
    public static async getLeadLogs(payload) {
        return await store.dispatch('GET_LEAD_LOGS', payload)
    }
    
    public static async getResponses(payload) {
        return await store.dispatch('GET_RESPONSES', payload)
    }
    
    public static async getInsurancePlan(payload) {
        return await store.dispatch('GET_INSURANCE_PLAN', payload)
    }

    public static async makeInvoice(payload) {
        return await store.dispatch('MAKE_INVOICE', payload)
    }

    public static async getMarkFlowQuoteDocs(payload) {
        return await store.dispatch('GET_MARK_FLOW_DOCUMENTS', payload)
    }
}

export default Customer