
import { defineComponent, computed, getCurrentInstance } from 'vue'
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();

        const isExistCustomer = computed(() => {
            return store.state.Customer.existCustomer
        })

        const isOpen = computed(() => {
            if(isExistCustomer.value) {
                return true
            }
            return false
        })

        const inst = getCurrentInstance()
        const toFirst = () => {
          setTimeout(() => {
            // alert('here')
              const existNoRef:any = inst?.refs?.existNoRef
              existNoRef.focus()
          }, 1000);
        }

        return {
            toFirst,
            isOpen,
            isExistCustomer
        }
    },
})
