
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import INotesList from "@/models/car/INotesList";

export interface NotesInfo {
    notesUrl:NotesUrlInfo;
    notesList:INotesList;
    errors:Array<string>;
    showNotesDom : any;
  }

export interface NotesUrlInfo{
  getLeadNotesListUrl:string;
  addLeadNotesListUrl:string;
}





@Module
export default class NotesModule extends VuexModule implements  NotesInfo{

    notesUrl = {} as NotesUrlInfo;
    notesList = {} as INotesList;
    showNotesDom = true;
    errors = [];
    
    @Action
    [Actions.PULL_NOTES](payload){ 
        return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_SHOW_NOTES_DOM,false);
            this.notesUrl.getLeadNotesListUrl = "/skye/car-leads/notes";
            ApiService.post(this.notesUrl.getLeadNotesListUrl,payload)
            .then(({data}) => {   
                this.context.commit(Mutations.SET_LEAD_NOTES_LIST,data.data);
                this.context.commit(Mutations.SET_SHOW_NOTES_DOM,true);
                resolve(data);
            })
            .catch(({ response }) => {   
                this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                reject(response);
            });
        })
    }
    @Action
    [Actions.ADD_NOTES](payload){  
        return new Promise<any>((resolve, reject) => {
            this.notesUrl.addLeadNotesListUrl = "/skye/car-leads/add-note";
            ApiService.post(this.notesUrl.addLeadNotesListUrl,payload)
            .then(({data}) => {   
                this.context.commit(Mutations.SET_LEAD_NOTES_LIST,data.data);
                resolve(data);
            })
            .catch(({ response }) => {   
                this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                reject(response);
            });
        })
    }
    
    @Mutation
    [Mutations.SET_NOTES_ERROR](error) { 
        for (const key in error) {
            this.errors = error[key];
          }
    }
    @Mutation
    [Mutations.SET_SHOW_NOTES_DOM](payload) : void {
    //   this.notesList = payload;
      this.showNotesDom = payload;
    }

    
    @Mutation
    [Mutations.SET_LEAD_NOTES_LIST](payload) : void {
      this.notesList = payload;
    //   this.showNotesDom = true;
    }
    get getNotesList(): INotesList {
        return  this.notesList;
    }

   
    get getNotesDomStatus(): any {
        return  this.showNotesDom;
    }
}
