<template>
    <el-dialog
        v-if="isOpen"
        v-model="isOpen"
        :width="common.isMobile() ? '90%' :'25%'"
        destroy-on-close
        append-to-body
        @close="closed"
  >

  <template #title>
    <h3 class="text-left">Update Name</h3>
  </template>
    
    <el-form
        ref="ruleFormNameRef"
        :model="customer"
        :rules="rules"
        label-position="top"
    >
        <el-form-item prop="name">
            <label for="customer-name" class="required"> Full Name </label>
            <vc-name 
                v-model="customer.name"
                id="customer-name"
                placeholder="Enter Full Name"
                ref="name"
            />
        </el-form-item> 
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">Cancel</el-button>
        <el-button type="primary" @click="handleSubmit(ruleFormNameRef)" :disabled="loading" :loading="loading">
          <span v-if="!loading">Save</span>
          <span v-if="loading">Please wait..</span>
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
    import { computed, defineExpose, reactive, ref } from 'vue'
    import Validations from '@/core/services/etc/Validations'
    import { useStore } from 'vuex'
    import { useUpdateCustomerData, useCustomerDetailsV2 } from '@/store/composable/Customer'
    import { notif } from '@/store/stateless/store'
    import { common } from '@/store/stateless/common'

    const store = useStore()
    const isOpen = ref(false)
    const ruleFormNameRef = ref()
    const loading = ref(false)
    const name = ref()

    const customer = computed(() => {
        return store.state.Customer.customer_edit
    })

    const rules = reactive({
        name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
    })

    
    function open() {
        store.commit('SET_CUSTOMER_DETAILS_EDIT')
        isOpen.value = true
        setTimeout(() => {
            name.value.focus()
        }, 500);
    }

    function closed() {
        isOpen.value = false
    }

    function handleSubmit(formEl) {
        if(!formEl) return
        formEl.validate(async(valid) => {
            loading.value = true
            if(valid) {
                const { name, customer_id } = customer.value
                const linkType = 'update-name'
                const response = await useUpdateCustomerData({ name, customer_id, linkType })
                useCustomerDetailsV2(customer_id, false)
                if(response.status < 299) notif.simple("Customer Name Updated", "success", "You have successfully update customer name")

                closed()
                loading.value = false
            } else {
                loading.value = false
                name.value.focus()
            }
        })
    }


    defineExpose({ open })
</script>
