
import { defineComponent, toRef, ref } from 'vue'
import { download, addLog } from "@/store/stateless/store";
import PolicyService from '@/core/services/car/PolicyService';

export default defineComponent({
    props: {
        dataOption: {
            type: Object,
            required: true
        },
        counting: {
            type: Number,
            required: true
        },
        disableUpload: {
          type: Boolean,
          required: false,
          default: false
        }
    },
    setup(_) {
        const opt = toRef(_, 'dataOption');

        const fileUpload = ref()
        const openFile = () => {
            fileUpload.value.click()
        }

        const uploadImage = async(e) => {
            const payload = new FormData()
            payload.append("id", opt.value.id)
            payload.append("file", e.target.files[0])

            await PolicyService.uploadDocumentData(payload)
        }

        const ApiPath = process.env.VUE_APP_API_URL;

        const getTextWidth = (text, font) => {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };

        return {
            ApiPath,
            addLog,
            download,
            opt,
            fileUpload,
            uploadImage,
            openFile,
            getTextWidth,
        }
    },
})
