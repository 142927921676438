import store from "@/store";

import { Actions } from "@/store/enums/StoreEnums";
import { App, computed, ref } from "vue";


// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class NotificationService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        NotificationService.vueInstance = app;
    }

    public static getNotificationList(payload,index) {
        payload.index = index;
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.GET_NOTIFICATION_LIST,payload)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { console.log(error); });
        });

    }

    public static getAllNotificationList(payload,index) {
        payload.index = index;
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.GET_ALL_NOTIFICATION_LIST,payload)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { console.log(error); });
        });

    }
    public static makeNotificationRead() {
       
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.MAKE_NOTIFICATION_READ)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { console.log(error); });
        });

    }
    public static getNotificationCount() {
      
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.GET_NOTIFICATION_COUNT)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { console.log(error); });
        });

    }

    public static updateNotificationClick(payload) {
       
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.UPDATE_NOTIFICATION_CLICK,payload)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { reject(error); });
        });

    }
}

export default NotificationService;
